import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadConfig } from '../../modules/config';
import AppComponent from '../../components/app';
import { withRouter } from 'react-router'

const mapStateToProps = state => {
    return {
        config: state.config,
        authentication: state.authentication
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({
    loadConfig,
}, dispatch);


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AppComponent));
