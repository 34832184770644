export const CONFIG_LOADED = 'form/CONFIG_LOADED';
export const STEP_UPDATED = 'form/STEP_UPDATED';
export const STEP_COMPLETED = 'form/STEP_COMPLETED';
export const FIELD_FOCUSED_OUT = 'form/FIELD_FOCUSED_OUT';
export const COORDINATES_FIELD_UPDATED = 'form/COORDINATES_FIELD_UPDATED';
export const HOME_FIELD_UPDATED = 'form/HOME_FIELD_UPDATED';
export const SECTOR_FIELD_UPDATED = 'form/SECTOR_FIELD_UPDATED';
export const REGISTRATION_FIELD_UPDATED = 'form/REGISTRATION_FIELD_UPDATED';
export const SUMMARY_FIELD_UPDATED = 'form/SUMMARY_FIELD_UPDATED';
export const SUBMITION_IN_PROGRESS = 'form/SUBMITION_IN_PROGRESS';
export const SUBMITION_SUCCESS = 'form/SUBMITION_SUCCESS';
export const SUBMITION_FAILURE = 'form/SUBMITION_FAILURE';
export const RESET_FORM = 'form/RESET_FORM';
export const RESET_FORM_REQUEST = 'form/RESET_FORM_REQUEST';
export const RESET_ABORT = 'form/RESET_ABORT';
export const VALIDITY_UPDATED = 'form/VALIDITY_UPDATED';

const initialFormState = {
    home: {
        role: '',
    },
    coordinates: {
        gender: '',
        firstname: '',
        lastname: '',
        email: '',
        emailConfirmation: '',
        phone: '',
        dirty: [],
    },
    sector: {
        sectorType: '',
        sectorName: '',
        dirty: [],
    },
    registration: {
        dirty: [],
        registeredToEvents: [],
        meal: 'none',
        attendanceCertificate: [],
        seconddayWorkshops: 'none',
        mealPayment: "Mandat administratif / Virement bancaire",
    },
    summary: {
        rulesAccepted: false,
    },
    completedSteps: [],
    registrationResponse: {
        registration: {
            events: [],
        }
    },
}

const initialState = {
    currentStepKey: 'home',
    currentStepIsValid: false,
    submitting: false,
    ...initialFormState
}

const pushUnique = (value, array) => {
    if (array.indexOf(value) === -1) {
        array.push(value);
    }
    return array;
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case STEP_UPDATED: {
            return {
                ...state,
                currentStepKey: action.payload
            }
        }

        case STEP_COMPLETED: {
            return {
                ...state,
                completedSteps: action.payload
            }
        }

        case FIELD_FOCUSED_OUT: {
            return {
                ...state,
                [state.currentStepKey]: {
                    ...state[state.currentStepKey],
                    dirty: pushUnique(action.payload.key, state[state.currentStepKey].dirty.slice())
                }
            }
        }

        case HOME_FIELD_UPDATED: {
            const { key, value, opts } = action.payload;
            if(opts && opts.maxLength && (value.length > opts.maxLength)) {
                return state;
            }

            return {
                ...state,
                home: {
                    ...state.home,
                    [key]: value,
                }
            }
        }
            
        case COORDINATES_FIELD_UPDATED: {
            const { key, value, opts } = action.payload;
            if(opts && opts.maxLength && (value.length > opts.maxLength)) {
                return state;
            }

            return {
                ...state,
                coordinates: {
                    ...state.coordinates,
                    [key]: value,
                }
            }
        }
            
        case SUMMARY_FIELD_UPDATED: {
            const { key, value, opts } = action.payload;
            if(opts && opts.maxLength && (value.length > opts.maxLength)) {
                return state;
            }

            return {
                ...state,
                summary: {
                    ...state.summary,
                    [key]: value,
                }
            }
        }

        case SECTOR_FIELD_UPDATED: {
            const { key, value, opts } = action.payload;
            if(opts && opts.maxLength && (value.length > opts.maxLength)) {
                return state;
            }

            let sectorFunction = state.sector.sectorFunction;
            let sectorFunctionOther = state.sector.sectorFunctionOther;
            let sectorStructure = state.sector.sectorStructure;
            let sectorStructureOther = state.sector.sectorStructureOther;
            if (key === 'sectorName') {
                sectorFunction = '';
                sectorFunctionOther = '';
                sectorStructure = '';
                sectorStructureOther = '';
            }
            return {
                ...state,
                sector: {
                    ...state.sector,
                    sectorFunction,
                    sectorFunctionOther,
                    sectorStructure,
                    sectorStructureOther,
                    [key]: value,
                    dirty: pushUnique(key, state.sector.dirty.slice())
                }
            }
        }

        case REGISTRATION_FIELD_UPDATED: {
            const { key, value, opts } = action.payload;
            if(opts && opts.maxLength && (value.length > opts.maxLength)) {
                return state;
            }
            
            return {
                ...state,
                registration: {
                    ...state.registration,
                    [key]: value,
                    dirty: pushUnique(key, state.registration.dirty.slice())
                }
            }
        }

        case SUBMITION_IN_PROGRESS: {
            return {
                ...state,
                submitting: true,
            }
        }

        case SUBMITION_SUCCESS: {
            return {
                ...state,
                submitting: false,
                registrationResponse: action.payload
            }
        }

        case SUBMITION_FAILURE: {
            return {
                ...state,
                submitting: false,
                submissionError: action.payload
            }
        }

        case RESET_FORM_REQUEST: {
            return {
                ...state,
                cancelling: true
            }
        }

        case RESET_ABORT: {
            return {
                ...state,
                cancelling: false
            }
        }

        case RESET_FORM: {
            return {
                ...initialState,
            }
        }

        case VALIDITY_UPDATED: {
            return {
                ...state,
                currentStepIsValid: action.payload,
            }
        }

        default:
            return state;
    }
}

export const blurField = (key) => dispatch => {
    dispatch({
        type: FIELD_FOCUSED_OUT,
        payload: { key }
    });
};

export const updateHomeField = (key, value, opts = {}) => dispatch => {
    dispatch({
        type: HOME_FIELD_UPDATED,
        payload: { key, value, opts },
    })
};

export const updateCoordinatesField = (key, value, opts) => dispatch => {
    dispatch({
        type: COORDINATES_FIELD_UPDATED,
        payload: { key, value, opts },
    })
};

export const updateSectorField = (key, value, opts) => dispatch => {
    dispatch({
        type: SECTOR_FIELD_UPDATED,
        payload: { key, value, opts },
    })
};

export const updateRegistrationField = (key, value, opts) => dispatch => {
    dispatch({
        type: REGISTRATION_FIELD_UPDATED,
        payload: { key, value, opts },
    })
};

export const updateSummaryField = (key, value, opts) => dispatch => {
    dispatch({
        type: SUMMARY_FIELD_UPDATED,
        payload: { key, value, opts },
    })
};


export const updateValidity = (payload) => dispatch => {
    dispatch({
        type: VALIDITY_UPDATED,
        payload,
    });
};

export const updateStep = (payload) => dispatch => {
    window.scrollTo(0, 0);
    dispatch({
        type: STEP_UPDATED,
        payload,
    });
    updateValidity(false)(dispatch);
};

export const updateCompletedStep = (payload) => dispatch => {
    dispatch({
        type: STEP_COMPLETED,
        payload
    });
}

export const resetFormRequest = (payload) => dispatch => {
    dispatch({
        type: RESET_FORM_REQUEST,
        payload
    });
};

export const resetForm = payload => dispatch => {
    dispatch({
        type: RESET_FORM,
        payload
    });
};

export const abortReset = payload => dispatch => {
    dispatch({
        type: RESET_ABORT,
        payload
    });
}

export const submit = data => async dispatch => {
    dispatch({
        type: SUBMITION_IN_PROGRESS,
        payload: data,
    });

    const response = await fetch(process.env.REACT_APP_API_HOST + '/registration', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    const json = await response.json();
    updateStep(data.nextStepKey)(dispatch);
    dispatch({
        type: SUBMITION_SUCCESS,
        payload: json,
    });
}

export default reducer;
