import React from "react";
import StepComponent from "../step";
import {
    RadioButton,
    FormField,
    Box,
    TextInput,
    Heading,
    CheckBox,
} from "grommet";
import renderHTML from "react-render-html";
import { isFieldValid, allValid } from "../../helpers/validation";
import WarningMessage from "../fields/warning-message";

const PRESENCE_DAY = "presenceDay";
const INV_ADDRESS_STREET = "invoicing-address:street";
const INV_ADDRESS_CS = "invoicing-address:cs";
const INV_ADDRESS_ZIPCODE = "invoicing-address:zipcode";
const INV_ADDRESS_CITY = "invoicing-address:city";
const INV_ADDRESS_CEDEX = "invoicing-address:cedex";
const MEAL = "meal";
const MEAL_PAYMENT = "mealPayment";

class RegistrationComponent extends StepComponent {
    constructor() {
        super();
        this.state = {
            showInvoicingAddressForm: false,
        };
    }

    getAddressFieldsValidations(checkDirty = false) {
        const fields = [
            INV_ADDRESS_CEDEX,
            INV_ADDRESS_ZIPCODE,
            INV_ADDRESS_CITY,
            INV_ADDRESS_CS,
            INV_ADDRESS_STREET,
        ];
        const formDirty = fields.find(
            (field) => this.props.dirty.indexOf(field) > -1
        );
        return {
            [INV_ADDRESS_STREET]: isFieldValid(
                {
                    propName: INV_ADDRESS_STREET,
                    value: this.props[INV_ADDRESS_STREET],
                    type: "string",
                    mandatory: formDirty,
                },
                checkDirty,
                this.props.dirty
            ),
            [INV_ADDRESS_ZIPCODE]: isFieldValid(
                {
                    propName: INV_ADDRESS_ZIPCODE,
                    value: this.props[INV_ADDRESS_ZIPCODE],
                    type: "string",
                    mandatory: formDirty,
                },
                checkDirty,
                this.props.dirty
            ),
            [INV_ADDRESS_CITY]: isFieldValid(
                {
                    propName: INV_ADDRESS_CITY,
                    value: this.props[INV_ADDRESS_CITY],
                    type: "string",
                    mandatory: formDirty,
                },
                checkDirty,
                this.props.dirty
            ),
        };
    }

    getFieldValidations(checkDirty = false) {
        const validationConfig = {
            ...this.getAddressFieldsValidations(checkDirty),
            [PRESENCE_DAY]: isFieldValid(
                {
                    propName: PRESENCE_DAY,
                    value: this.props[PRESENCE_DAY],
                    type: "string",
                    mandatory: true,
                },
                checkDirty,
                this.props.dirty
            ),
            ...(this?.props?.home?.role === "congressiste"
                ? {
                    [MEAL]: isFieldValid(
                        {
                            propName: MEAL,
                            value: this.props[MEAL],
                            type: "string",
                            mandatory: true,
                        },
                        checkDirty,
                        this.props.dirty
                    ),
                }
                : {}),
            ...(this?.props?.home?.role === "congressiste" &&
                this?.props?.[MEAL] === "réservation"
                ? {
                    [MEAL_PAYMENT]: isFieldValid(
                        {
                            propName: MEAL_PAYMENT,
                            value: this.props[MEAL_PAYMENT],
                            type: "string",
                            mandatory: true,
                        },
                        checkDirty,
                        this.props.dirty
                    ),
                }
                : {}),
        };
        return validationConfig;
    }

    isValid() {
        return allValid(this.getFieldValidations(true));
    }

    pendingListWarning(eventId) {
        const event = this.props.balance.events.find(
            (evt) => evt.id === eventId
        );
        if (!event) {
            return "";
        }
        if (event.registrationsBalance <= 0) {
            return (
                <div className="registration-warning-message">
                    Le nombre d'inscriptions maximum a été atteint pour cette
                    visite.
                    <br />
                    Vous pouvez tout de même vous y inscrire mais serez placé(e)
                    sur liste d'attente.
                </div>
            );
        } else return "";
    }

    componentWillUnmount() {
        let events = [];
        this.props.config.days.forEach((day) => {
            events = events.concat(day.events);
        });

        const registeredSingleEvents = events.filter((event) => {
            return !!this.props[event.id];
        });

        const newVal = this.props.registeredToEvents.slice();
        // add to registered the events for wich we have a property defined to 'true' on this.props
        registeredSingleEvents.forEach((event) => {
            const eventIndex = newVal.indexOf(event.id);
            if (this.props[event.id] === "true" && eventIndex === -1) {
                newVal.push(event.id);
            } else if (this.props[event.id] === "false") {
                newVal.splice(eventIndex, 1);
            }
        });
        //add second day workshops
        const secondDayEvents = this.props.config.days.find(
            (day) => day.key === "second"
        ).events;
        const workshopEvents = secondDayEvents.filter(
            (event) => event.type === "workshop"
        );

        workshopEvents.forEach((event) => {
            const eventIndex = newVal.indexOf(event.id);
            if (eventIndex >= 0) {
                newVal.splice(eventIndex, 1);
            }
        });

        const foundEvent = newVal.find(
            (eventId) => this.props.seconddayWorkshops.indexOf(eventId) >= 0
        );
        if (!foundEvent) {
            newVal.push(this.props.seconddayWorkshops);
        }

        this.props.updateField("registeredToEvents", newVal);
    }

    addressForm() {
        return (
            <Box pad={{ vertical: "medium" }}>
                <Box alignSelf="center">
                    Compléter les champs obligatoires ci-dessous (Adresse, Code
                    postal et Commune).
                </Box>
                <Box direction="row">
                    <Box size="medium" direction="row" align="center">
                        <WarningMessage
                            when={
                                !this.getFieldValidations(true)[
                                INV_ADDRESS_STREET
                                ]
                            }
                            severity="error"
                            message="Ce champ est obligatoire"
                        />
                        <FormField>
                            <TextInput
                                placeHolder="Adresse"
                                {...this.connectText(INV_ADDRESS_STREET)}
                            />
                        </FormField>
                    </Box>
                    <Box direction="row">
                        <FormField>
                            <TextInput
                                placeHolder="BP/CS"
                                {...this.connectText(INV_ADDRESS_CS)}
                            />
                        </FormField>
                    </Box>
                </Box>
                <Box direction="row">
                    <Box size="auto" direction="row">
                        <WarningMessage
                            when={
                                !this.getFieldValidations()[INV_ADDRESS_ZIPCODE]
                            }
                            severity="error"
                            message="Ce champ est obligatoire"
                        />
                        <FormField>
                            <TextInput
                                placeHolder="Code postal"
                                {...this.connectText(INV_ADDRESS_ZIPCODE)}
                            />
                        </FormField>
                    </Box>
                    <Box size="auto" direction="row">
                        <WarningMessage
                            when={!this.getFieldValidations()[INV_ADDRESS_CITY]}
                            severity="error"
                            message="Ce champ est obligatoire"
                        />
                        <FormField>
                            <TextInput
                                placeHolder="Commune"
                                {...this.connectText(INV_ADDRESS_CITY)}
                            />
                        </FormField>
                    </Box>
                    <Box size="auto" direction="row">
                        <FormField>
                            <TextInput
                                placeHolder="Cedex"
                                {...this.connectText(INV_ADDRESS_CEDEX)}
                            />
                        </FormField>
                    </Box>
                </Box>
            </Box>
        );
    }

    lunchForm(day) {
        const footer = day.meal.footer;
        const footerFoodTruck = day.meal.footerFoodTruck;
        const mealPaymentForm = (
            <Box direction="column">
                <Box
                    basis="3/4"
                    className="white-background"
                    pad="medium"
                    margin="small"
                >
                    <b>Règlement par :</b>
                    <Box direction="column">
                        {day.meal.payments.map((payment, index) => (
                            <Box key={payment} margin={{ top: "small" }}>
                                <RadioButton
                                    id={`radio-mealpayment-${index}`}
                                    label={renderHTML(payment)}
                                    {...this.connectRadio(
                                        "mealPayment",
                                        payment
                                    )}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box
                    basis="3/4"
                    className="white-background"
                    pad="medium"
                    margin="small"
                >
                    <Box>{renderHTML(footer)}</Box>
                    {!this.state.showInvoicingAddressForm && (
                        <span
                            className="brand-color"
                            style={{
                                cursor: "pointer",
                                display: "block",
                                textAlign: "center",
                                marginTop: "10px",
                            }}
                            role="button"
                            onClick={() =>
                                this.setState({
                                    showInvoicingAddressForm: true,
                                })
                            }
                        >
                            Vous souhaitez utiliser une adresse de facturation
                            différente ?
                        </span>
                    )}
                    {this.state.showInvoicingAddressForm
                        ? this.addressForm()
                        : ""}
                </Box>
            </Box>
        );

        const mealPaymentFormFoodTruck = (
            <Box direction="column">
                <Box
                    basis="3/4"
                    className="white-background"
                    pad="medium"
                    margin="small"
                >
                    <b>Règlement par :</b>
                    <Box direction="column">
                        {day.meal.paymentsFoodTruck.map((payment, index) => (
                            <Box key={payment} margin={{ top: "small" }}>
                                <RadioButton
                                    id={`radio-mealpayment-${index}`}
                                    label={payment}
                                    {...this.connectRadio(
                                        "mealPayment",
                                        payment
                                    )}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box
                    basis="3/4"
                    className="white-background"
                    pad="medium"
                    margin="small"
                >
                    <Box>{renderHTML(footerFoodTruck)}</Box>
                    {!this.state.showInvoicingAddressForm && (
                        <span
                            className="brand-color"
                            style={{
                                cursor: "pointer",
                                display: "block",
                                textAlign: "center",
                                marginTop: "10px",
                            }}
                            role="button"
                            onClick={() =>
                                this.setState({
                                    showInvoicingAddressForm: true,
                                })
                            }
                        >
                            Vous souhaitez utiliser une adresse de facturation
                            différente ?
                        </span>
                    )}
                    {this.state.showInvoicingAddressForm
                        ? this.addressForm()
                        : ""}
                </Box>
            </Box>
        );

        const noRoomLeft =
            this?.props?.balance?.meal?.registrationsBalance <= 0;
        return this?.props?.home?.role === "congressiste" ? (
            <div>
                <Box pad="small" direction="row">
                    {renderHTML(day.meal.header)}
                </Box>
                <Box
                    className="form-group"
                    pad="medium"
                    direction="column"
                    justify="between"
                >
                    <Box
                        direction="row"
                        className="checkbox-wrapper"
                        justify="between"
                    >
                        <RadioButton
                            disabled={
                                noRoomLeft ||
                                Date.now() > new Date(day.meal.limitDatetime)
                            }
                            label="Je réserve un déjeuner au salon de restauration officiel"
                            name="inlineRadioOptions"
                            id="mealBook"
                            {...this.connectRadio("meal", "réservation")}
                        />
                        <Box>Total: {day.meal.price}€ TTC</Box>
                    </Box>
                    {noRoomLeft ? (
                        <div className="registration-warning-message">
                            Capacité de salle atteinte : nous sommes au regret
                            de ne pouvoir prendre votre réservation.
                            <br />
                        </div>
                    ) : null}
                    {Date.now() > new Date(day.meal.limitDatetime) ? (
                        <div className="registration-warning-message">
                            Date limite de réservation dépassée : nous sommes au
                            regret de ne pouvoir prendre votre réservation.
                            <br />
                        </div>
                    ) : null}
                    {this.props.meal === "réservation" ? mealPaymentForm : ""}
                </Box>
                {/* <Box
                    className="form-group"
                    pad="medium"
                    direction="column"
                    justify="between"
                >
                    <Box
                        direction="row"
                        className="checkbox-wrapper"
                        justify="between"
                    >
                        <RadioButton
                            label="Je réserve un déjeuner à la Brasserie du Quartz"
                            name="inlineRadioOptions"
                            id="mealFoodtruck"
                            {...this.connectRadio("meal", "foodtruck")}
                        />
                        <Box>Total: {day.meal.price}€/TTC</Box>
                    </Box>
                    {this.props.meal === "foodtruck" ? mealPaymentFormFoodTruck : ""}
                </Box> */}
                <Box
                    className="form-group"
                    pad="medium"
                    direction="column"
                    justify="between"
                >
                    <Box direction="column" className="checkbox-wrapper">
                        <RadioButton
                            label="Je ne déjeunerai pas sur place"
                            name="inlineRadioOptions"
                            id="mealNone"
                            {...this.connectRadio("meal", "none")}
                        />
                    </Box>
                </Box>
            </div>
        ) : (
            <div>
                <Box pad="small" direction="column">
                    <ul>
                        <li>
                            {/* Déjeuner en salle de restauration « Les jardins du
                            Prieuré » (réservation obligatoire) */}
                            Déjeuner en salon de restauration officiel « Les Jardins du Prieuré » 29 euros TTC (réservation obligatoire)
                        </li>
                        <li>
                            Plateau repas servi sur votre stand le jeudi midi « Les Jardins du Prieuré » 21,45 €TTC(réservation obligatoire)
                            {/* Plateau repas au tarif de 20,68 € TTC « Les jardins
                            du Prieuré » (réservation obligatoire) */}
                        </li>
                    </ul>
                    <div style={{
                        marginTop: '-15px',
                        marginLeft: '5px',
                        fontSize: '0.9rem',
                        color: 'black'
                    }}>NB : Un service de Brasserie est également proposé au rez-de-chaussée du Quartz (sans réservation, dans la limite des places disponibles).</div><br />
                    {/* <b>Propositions « Les Jardins du Prieuré »</b> */}
                    <b>Propositions de déjeuners</b>
                    <ul>
                        <li>
                            <p>
                                <b>Déjeuner en salon de restauration officiel</b>
                            </p>
                            <div>
                                Le prix du déjeuner est de 29€ TTC / personne (sur réservation uniquement, places
                                limitées).{" "}
                            </div>
                            <div>
                                Celui-ci comprend entrée, plat, dessert, boisson et café.
                            </div>
                            <a
                                href={`${process.env.REACT_APP_API_HOST}/public/ccf-menu-dejeuner_03102024.pdf`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Consultez le menu proposé
                            </a>
                        </li>
                        {/* <li>
                            <p>
                                <b>Déjeuner à la Brasserie du Quartz</b>
                            </p>
                            <div>
                                Le prix du déjeuner est de 29€ TTC / personne (sur réservation uniquement, places
                                limitées).{" "}
                            </div>
                            <div>
                                Celui-ci comprend entrée, plat, dessert, boisson et café.
                            </div>
                            <a
                                href="https://amf29.asso.fr/wp-content/uploads/2022/07/ccf-menu-dejeuner_06102022.pdf"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Consultez le menu proposé
                            </a>
                        </li> */}
                        <li>
                            <p>
                                <b>Plateau repas au tarif de 21,45 € TTC</b>{" "}
                                (Deux options dont une végétarienne, sur réservation uniquement, se référer au cahier des charges Exposant)
                                <br />
                            </p>
                            {/* <div>
                                Coffret à consommer froid :
                                <ul>
                                    <li>Entrée du jour</li>
                                    <li>Plat du jour</li>
                                    <li>Fromage affiné</li>
                                    <li>Douceur sucrée</li>
                                    <li>
                                        Pain, beurre, sel, poivre, couverts et
                                        serviette jetable
                                    </li>
                                </ul>
                            </div> */}
                        </li>
                    </ul>
                    <span>
                        Si vous souhaitez réserver des déjeuners sur place ou
                        des plateaux repas, vous êtes invité(e) à prendre
                        contact avec l'Agence BERGAME en charge de la gestion
                        des déjeuners des exposants au 02 98 46 05 17 ou à{" "}
                        <a
                            href="mailto:ccf2024@agence-bergame.bzh"
                            target="_blank"
                            rel="noreferrer"
                        >
                            ccf2024@agence-bergame.bzh
                        </a>
                        .
                    </span>
                    <br />
                    <span>
                        Votre réservation ne sera prise en compte qu'à réception
                        de votre règlement à l'ordre de l'agence BERGAME à
                        adresser à l'Agence BERGAME (25, avenue Clémenceau -
                        29200 BREST).
                    </span>
                    <br />
                    <span>
                        <b>Important : </b>à partir du 10 septembre 2024 toute
                        réservation est définitive. Chaque repas réservé sera dû
                        (pas d'annulation ni de modification possible)
                    </span>
                    <br />
                    <span>
                        Pour les repas en salle de restauration : <br />
                        Un bracelet vous sera remis sur place par les
                        organisateurs lors de votre installation le mercredi 2
                        octobre pour accéder aux espaces de restauration le lendemain
                        (uniquement si votre règlement est effectué)
                    </span>
                </Box>
            </div>
        );
    }

    firstFormRender() {
        const day = this.props.config.days
            .filter((day) => day.key === "first")
            .pop();
        const cocktailEvents = day.events.filter(
            (event) => event.type === "cocktail"
        );
        const cocktailEventsKeys = cocktailEvents.map((event) => event.id);
        const cocktailEventsRadios = cocktailEvents.map((event) => {
            return (
                <Box
                    className="form-group"
                    pad="medium"
                    direction="row"
                    key={event.id}
                >
                    <div>
                        <CheckBox
                            key={event.id}
                            label={event.formLabel}
                            {...this.connectCheckbox(
                                "registeredToEvents",
                                event.id
                            )}
                        />
                    </div>
                    {this.pendingListWarning(event.id)}
                </Box>
            );
        });
        cocktailEventsRadios.push(
            <Box className="form-group" pad="medium" direction="row" key="none">
                <CheckBox
                    key="firstday-no-cocktail"
                    label="Je ne participerai à aucun cocktail"
                    checked={(() => {
                        return !cocktailEventsKeys.filter((eventKey) => {
                            return this.props.registeredToEvents.find(
                                (event) => eventKey === event
                            );
                        }).length;
                    })()}
                    onChange={() => {
                        const newValue = this.props.registeredToEvents
                            .slice()
                            .filter((eventId) => {
                                return (
                                    cocktailEventsKeys.indexOf(eventId) === -1
                                );
                            });
                        this.props.updateField("registeredToEvents", newValue);
                    }}
                />
            </Box>
        );
        return (
            <div key="first">
                <Heading tag="h2" className="form-group-title">
                    Cocktails du {day.labelDay + " " + day.labelMonth}
                </Heading>
                {cocktailEventsRadios}
                {this?.props?.home?.role === "exposant" ? (
                    <Heading tag="h2" className="form-group-title">
                        Offre de restauration sur place -{" "}
                        {day.labelDay + " " + day.labelMonth}
                    </Heading>
                ) : (
                    <Heading tag="h2" className="form-group-title">
                        Restauration sur place -{" "}
                        {day.labelDay + " " + day.labelMonth}
                    </Heading>
                )}
                {this.lunchForm(day)}
            </div>
        );
    }

    secondFormRender() {
        const day = this.props.config.days.find((day) => day.key === "second");
        const cocktailEvent = day.events.find(
            (event) => event.type === "cocktail"
        );
        const workshopEvents = day.events.filter(
            (event) => event.type === "workshop"
        );

        const workshopForm = workshopEvents.map((event) => {
            return (
                <Box className="form-group" pad="medium" key={event.id}>
                    <div>
                        <RadioButton
                            key={"radio-workshop-" + event.id}
                            id={"radio-workshop-" + event.id}
                            label={event.formLabel}
                            {...this.connectRadio(
                                "seconddayWorkshops",
                                event.id
                            )}
                        />
                    </div>
                    {this.pendingListWarning(event.id)}
                </Box>
            );
        });

        workshopForm.push(
            <Box className="form-group" pad="medium" direction="row">
                <RadioButton
                    key="radio-workshop-none"
                    id="radio-workshop-none"
                    label="Je ne participerai à aucune visite"
                    {...this.connectRadio("seconddayWorkshops", "none")}
                />
            </Box>
        );

        return (
            <div key="second">
                <Heading tag="h2" className="form-group-title">
                    Cocktail déjeunatoire du{" "}
                    {day.labelDay + " " + day.labelMonth}
                </Heading>
                <Box className="form-group" pad="medium" direction="row">
                    <RadioButton
                        id="radio-cocktail-secondday"
                        label="Je participerai au cocktail déjeunatoire de clôture à 12h30 (à l'invitation de Engie et l'AMF 29)"
                        {...this.connectRadio(cocktailEvent.id, "true")}
                    />
                </Box>
                <Box className="form-group" pad="medium" direction="row">
                    <RadioButton
                        id="radio-cocktail-secondday-none"
                        label="Je ne participerai pas au cocktail déjeunatoire de clôture"
                        {...this.connectRadio(cocktailEvent.id, "false", true)}
                    />
                </Box>
                {/* <Heading tag="h2" className="form-group-title">Visites guidées gratuites du {day.labelDay + ' ' + day.labelMonth}</Heading>
                <Box pad="small" direction="row">
                    {renderHTML(this.props.config.messages.workshopWarning || '')}
                </Box> */}
                {/* {workshopForm} */}
            </div>
        );
    }

    render() {
        const dayRadios = this.props.config.days.map((day) => {
            return (
                <RadioButton
                    key={"radio-presenceday-" + day.key}
                    id={"radio-presenceday-" + day.key}
                    label={day.labelDay + " " + day.labelMonth}
                    name={PRESENCE_DAY}
                    {...this.connectRadio(PRESENCE_DAY, day.key)}
                />
            );
        });

        let allDaysLabel = this.props.config.days
            .map((day) => day.labelDay)
            .join(" & ");
        allDaysLabel += " " + this.props.config.days[0].labelMonth;

        const allDaysValue = this.props.config.days
            .map((day) => day.key)
            .join(",");

        dayRadios.push(
            <RadioButton
                key="radio-presenceday-all"
                id="radio-presenceday-all"
                label={allDaysLabel}
                name={PRESENCE_DAY}
                {...this.connectRadio(PRESENCE_DAY, allDaysValue)}
            />
        );

        const selectedDays = this.props[PRESENCE_DAY]
            ? this.props[PRESENCE_DAY].split(",")
            : [];
        const dayForms = selectedDays.map((dayKey) => {
            return this[dayKey + "FormRender"]();
        });

        const attendanceForm = (
            <div>
                <Heading tag="h2" className="form-group-title">
                    Attestation
                </Heading>
                <Box className="form-group" pad="medium" direction="row">
                    <CheckBox
                        label="Je souhaite recevoir une attestation de présence"
                        {...this.connectCheckbox(
                            "attendanceCertificate",
                            "oui"
                        )}
                    />
                </Box>
            </div>
        );

        return (
            <div>
                <Heading tag="h2" className="form-group-title">
                    Quand serez-vous présent(e) ?
                </Heading>
                <Box className="form-group" pad="medium" direction="row">
                    {dayRadios}
                </Box>
                {dayForms}
                {this.props[PRESENCE_DAY] &&
                    this?.props?.home?.role === "congressiste"
                    ? attendanceForm
                    : ""}
            </div>
        );
    }
}

export default RegistrationComponent;
