import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./store";
import { App } from "grommet";
import BergameApp from "./containers/app";
import reportWebVitals from "./reportWebVitals";

import "./index.scss";
import "font-awesome/scss/font-awesome.scss";

const root = document.querySelector("#root");

render(
    <Provider store={store}>
        <App centered={false} lang="fr-FR">
            <ConnectedRouter history={history}>
                <BergameApp />
            </ConnectedRouter>
        </App>
    </Provider>,
    root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
