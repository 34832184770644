import React from "react";
import Box from "grommet/components/Box";
import RadioButton from "grommet/components/RadioButton";
import FormField from "grommet/components/FormField";
import TextInput from "grommet/components/TextInput";
import Heading from "grommet/components/Heading";
import TrashIcon from "grommet/components/icons/base/Trash";
import Button from "grommet/components/Button";
import SaveIcon from "grommet/components/icons/base/Save";
import SelectOrText from "../fields/SelectOrText";
import { Redirect } from "react-router-dom";
import { findSuggestions } from "../../helpers/suggestions";
import DocumentPdfIcon from "grommet/components/icons/base/DocumentPdf";

class InscriptionComponent extends React.Component {
    UNSAFE_componentWillMount() {
        this.props.fetchDetails(this.props.match.params.id, this.props.token);
    }

    addOtherField = (arrays) => {
        return arrays.map((a) => [
            ...a,
            {
                label: "Autre...",
                value: "other",
            },
        ]);
    };

    connectText = (field, subfield) => {
        const value = subfield
            ? this.props.inscription[field][subfield]
            : this.props.inscription[field];
        return {
            value: value === "other" ? "" : value,
            onDOMChange: (event) =>
                this.props.changeDetailsField(
                    [field, subfield],
                    event.target.value
                ),
            onSelect: (itemSelected) =>
                this.props.changeDetailsField(
                    [field, subfield],
                    itemSelected.suggestion
                ),
        };
    };

    connectSelectOrText = (field, subfield) => ({
        value: subfield
            ? this.props.inscription[field][subfield]
            : this.props.inscription[field],
        onChange: (event) =>
            this.props.changeDetailsField([field, subfield], event.value),
        onBlur: (event) =>
            this.props.changeDetailsField([field, subfield], event.value),
    });

    handleDayPresenceChange = (dayKey, present) => () => {
        const presenceDay = this.props.inscription.registration.presenceDay;
        const newPresenceDay = presenceDay.filter((pDay) => pDay !== dayKey);
        if (present) newPresenceDay.push(dayKey);
        this.props.changeDetailsField(
            ["registration", "presenceDay"],
            newPresenceDay
        );
    };

    handleSaveClick = () => {
        this.props.saveInscription(this.props.inscription, this.props.token);
    };

    handleDeleteClick = () => {
        if (confirm("Vous êtes sur le point de supprimer cette inscription")) { // eslint-disable-line no-restricted-globals
            this.props.deleteInscription(
                this.props.inscription,
                this.props.token
            );
            this.props.history.replace("/administration/inscriptions");
        }
    };

    render() {
        const { inscription, config } = this.props;
        const townships = findSuggestions(
            config.sectors.public.townHall.townships,
            this.props.inscription.sector.sectorStructure
        );
        const _townhallFunctions = config.sectors.public.townHall.functions;
        const epcis = findSuggestions(
            config.sectors.public.community.epcis,
            this.props.inscription.sector.sectorStructure
        );
        const _epciFunctions = config.sectors.public.community.functions;
        const _publicServices = config.sectors.public.other.services;
        const _otherFunctions = config.sectors.other;
        const [
            townhallFunctions,
            epciFunctions,
            publicServices,
            otherFunctions,
        ] = this.addOtherField([
            _townhallFunctions,
            _epciFunctions,
            _publicServices,
            _otherFunctions,
        ]);

        return (
            <React.Fragment>
                {this.props.inscription && !this.props.inscription._id ? (
                    <Redirect to="/administration/inscriptions" />
                ) : null}
                <Box justify="center" direction="row" margin="medium">
                    <Button
                        primary
                        className="flex110"
                        style={{ marginRight: "10px" }}
                        label="Enregistrer"
                        icon={<SaveIcon />}
                        onClick={
                            this.props.saving || this.props.deleting
                                ? null
                                : this.handleSaveClick
                        }
                    />
                    <Button
                        className="flex110"
                        style={{ marginLeft: "10px" }}
                        label="Supprimer"
                        icon={<TrashIcon />}
                        onClick={
                            this.props.saving || this.props.deleting
                                ? null
                                : this.handleDeleteClick
                        }
                    />
                    <Button
                        className="flex110"
                        style={{ marginLeft: "10px" }}
                        label="Badge"
                        target="_blank"
                        href={`${process.env.REACT_APP_API_HOST}/badge/${inscription._id}/pdf`}
                        onClick={(e) => e.stopPropagation()}
                        icon={<DocumentPdfIcon />}
                    ></Button>
                </Box>
                <Box pad="medium">
                    <Heading tag="h2">Coordonnées</Heading>
                    <Box className="form-group" pad="medium">
                        <Box direction="row" className="checkbox-wrapper">
                            <RadioButton
                                label="Madame"
                                name="gender"
                                id="gender-female"
                                checked={
                                    inscription.coordinates.gender === "female"
                                }
                                onChange={() =>
                                    this.props.changeDetailsField(
                                        ["coordinates", "gender"],
                                        "female"
                                    )
                                }
                            />
                            <RadioButton
                                label="Monsieur"
                                name="gender"
                                id="gender-male"
                                checked={
                                    inscription.coordinates.gender === "male"
                                }
                                onChange={() =>
                                    this.props.changeDetailsField(
                                        ["coordinates", "gender"],
                                        "male"
                                    )
                                }
                            />
                        </Box>
                        <Box direction="row">
                            <FormField>
                                <TextInput
                                    placeHolder="Prénom"
                                    {...this.connectText(
                                        "coordinates",
                                        "firstname"
                                    )}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    placeHolder="Nom"
                                    {...this.connectText(
                                        "coordinates",
                                        "lastname"
                                    )}
                                />
                            </FormField>
                        </Box>
                        <Box direction="row">
                            <FormField>
                                <TextInput
                                    type="email"
                                    placeHolder="E-mail"
                                    {...this.connectText(
                                        "coordinates",
                                        "email"
                                    )}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    placeHolder="Téléphone"
                                    {...this.connectText(
                                        "coordinates",
                                        "phone"
                                    )}
                                />
                            </FormField>
                        </Box>
                    </Box>
                    <Heading tag="h2">Secteur</Heading>
                    <Box className="form-group" pad="medium">
                        <Box direction="row" pad="medium">
                            <Heading style={{ flex: 1 }} tag="h3">
                                Secteur d'activité
                            </Heading>
                            <Box
                                style={{ flex: 2 }}
                                direction="row"
                                className="checkbox-wrapper"
                            >
                                <RadioButton
                                    label="Secteur public"
                                    name="sector-type"
                                    id="sector-type-public"
                                    checked={
                                        inscription.sector.sectorType ===
                                        "Public"
                                    }
                                    onChange={() =>
                                        this.props.changeDetailsField(
                                            ["sector", "sectorType"],
                                            "Public"
                                        )
                                    }
                                />
                                <RadioButton
                                    label="Secteur privé"
                                    name="sector-type"
                                    id="sector-type-private"
                                    checked={
                                        inscription.sector.sectorType ===
                                        "Privé"
                                    }
                                    onChange={() =>
                                        this.props.changeDetailsField(
                                            ["sector", "sectorType"],
                                            "Privé"
                                        )
                                    }
                                />
                                <RadioButton
                                    label="Autre"
                                    name="sector-type"
                                    id="sector-type-other"
                                    checked={
                                        inscription.sector.sectorType ===
                                        "Autre"
                                    }
                                    onChange={() =>
                                        this.props.changeDetailsField(
                                            ["sector", "sectorType"],
                                            "Autre"
                                        )
                                    }
                                />
                            </Box>
                        </Box>
                        <Box pad="medium">
                            {inscription.sector.sectorType === "Public" && (
                                <React.Fragment>
                                    <Box direction="row">
                                        <Heading style={{ flex: 1 }} tag="h3">
                                            Secteur public
                                        </Heading>
                                        <Box
                                            style={{ flex: 2 }}
                                            direction="row"
                                            className="checkbox-wrapper"
                                        >
                                            <RadioButton
                                                label="Mairie"
                                                name="sector-name"
                                                id="sector-name-mairie"
                                                checked={
                                                    inscription.sector
                                                        .sectorName === "Mairie"
                                                }
                                                onChange={() =>
                                                    this.props.changeDetailsField(
                                                        [
                                                            "sector",
                                                            "sectorName",
                                                        ],
                                                        "Mairie"
                                                    )
                                                }
                                            />
                                            <RadioButton
                                                label="CC / CA / Métropole"
                                                name="sector-name"
                                                id="sector-public-collectivite"
                                                checked={
                                                    inscription.sector
                                                        .sectorName ===
                                                    "Collectivité"
                                                }
                                                onChange={() =>
                                                    this.props.changeDetailsField(
                                                        [
                                                            "sector",
                                                            "sectorName",
                                                        ],
                                                        "Collectivité"
                                                    )
                                                }
                                            />
                                            <RadioButton
                                                label="Autre"
                                                name="sector-name"
                                                id="sector-other-other"
                                                checked={
                                                    inscription.sector
                                                        .sectorName === "Autre"
                                                }
                                                onChange={() =>
                                                    this.props.changeDetailsField(
                                                        [
                                                            "sector",
                                                            "sectorName",
                                                        ],
                                                        "Autre"
                                                    )
                                                }
                                            />
                                        </Box>
                                    </Box>
                                    {inscription.sector.sectorName ===
                                        "Mairie" && (
                                        <React.Fragment>
                                            <Box>
                                                <FormField className="select-full-width">
                                                    <TextInput
                                                        placeHolder="Commune"
                                                        id="township-input"
                                                        name="township-input"
                                                        suggestions={townships}
                                                        {...this.connectText(
                                                            "sector",
                                                            "sectorStructure"
                                                        )}
                                                    />
                                                </FormField>
                                            </Box>
                                            <Box>
                                                <SelectOrText
                                                    textfieldPlaceHolder="Fonction"
                                                    placeHolder="Fonction"
                                                    enableSearch={true}
                                                    options={townhallFunctions}
                                                    {...this.connectSelectOrText(
                                                        "sector",
                                                        "sectorFunction"
                                                    )}
                                                />
                                            </Box>
                                            {inscription.sector
                                                .sectorFunction === "other" && (
                                                <Box>
                                                    <FormField className="input-full-width">
                                                        <TextInput
                                                            placeHolder="Nom de la fonction"
                                                            {...this.connectText(
                                                                "sector",
                                                                "sectorFunctionOther"
                                                            )}
                                                        />
                                                    </FormField>
                                                </Box>
                                            )}
                                        </React.Fragment>
                                    )}
                                    {inscription.sector.sectorName ===
                                        "Collectivité" && (
                                        <React.Fragment>
                                            <Box>
                                                <FormField className="select-full-width">
                                                    <TextInput
                                                        placeHolder="Saisissez les premières lettres de l'intercommunalité"
                                                        id="ecpi-input"
                                                        name="ecpi-input"
                                                        suggestions={epcis}
                                                        {...this.connectText(
                                                            "sector",
                                                            "sectorStructure"
                                                        )}
                                                    />
                                                </FormField>
                                            </Box>
                                            <Box>
                                                <SelectOrText
                                                    textfieldPlaceHolder="Fonction"
                                                    placeHolder="Fonction"
                                                    enableSearch={true}
                                                    options={epciFunctions}
                                                    {...this.connectSelectOrText(
                                                        "sector",
                                                        "sectorFunction"
                                                    )}
                                                />
                                            </Box>
                                            {inscription.sector
                                                .sectorFunction === "other" && (
                                                <Box>
                                                    <FormField className="input-full-width">
                                                        <TextInput
                                                            placeHolder="Nom de la fonction"
                                                            {...this.connectText(
                                                                "sector",
                                                                "sectorFunctionOther"
                                                            )}
                                                        />
                                                    </FormField>
                                                </Box>
                                            )}
                                        </React.Fragment>
                                    )}
                                    {inscription.sector.sectorName ===
                                        "Autre" && (
                                        <React.Fragment>
                                            <Box>
                                                <SelectOrText
                                                    textfieldPlaceHolder="Structure"
                                                    placeHolder="Structure"
                                                    enableSearch={true}
                                                    options={publicServices}
                                                    {...this.connectSelectOrText(
                                                        "sector",
                                                        "sectorStructure"
                                                    )}
                                                />
                                            </Box>
                                            {inscription.sector
                                                .sectorStructure ===
                                                "other" && (
                                                <Box>
                                                    <FormField className="input-full-width">
                                                        <TextInput
                                                            placeHolder="Nom de la structure"
                                                            {...this.connectText(
                                                                "sector",
                                                                "sectorStructureOther"
                                                            )}
                                                        />
                                                    </FormField>
                                                </Box>
                                            )}
                                            <Box>
                                                <FormField className="select-full-width">
                                                    <TextInput
                                                        placeHolder="Fonction"
                                                        id="public-other-function-input"
                                                        name="public-other-function-input"
                                                        {...this.connectText(
                                                            "sector",
                                                            "sectorFunction"
                                                        )}
                                                    />
                                                </FormField>
                                            </Box>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                            {inscription.sector.sectorType === "Privé" && (
                                <React.Fragment>
                                    <Heading style={{ flex: 1 }} tag="h3">
                                        Secteur privé
                                    </Heading>
                                    <Box>
                                        <FormField className="input-full-width">
                                            <TextInput
                                                placeHolder="Nom de la structure"
                                                id="sector-private-structure"
                                                name="sector-private-structure"
                                                {...this.connectText(
                                                    "sector",
                                                    "sectorStructure"
                                                )}
                                            />
                                        </FormField>
                                    </Box>
                                    <Box>
                                        <FormField className="input-full-width">
                                            <TextInput
                                                placeHolder="Nom de la fonction"
                                                id="sector-private-function"
                                                name="sector-private-function"
                                                {...this.connectText(
                                                    "sector",
                                                    "sectorFunction"
                                                )}
                                            />
                                        </FormField>
                                    </Box>
                                </React.Fragment>
                            )}
                            {inscription.sector.sectorType === "Autre" && (
                                <React.Fragment>
                                    <Heading style={{ flex: 1 }} tag="h3">
                                        Autre
                                    </Heading>
                                    <Box>
                                        <FormField className="input-full-width">
                                            <TextInput
                                                placeHolder="Nom de la structure"
                                                {...this.connectText(
                                                    "sector",
                                                    "sectorStructure"
                                                )}
                                            />
                                        </FormField>
                                    </Box>
                                    <Box>
                                        <SelectOrText
                                            textfieldPlaceHolder="Fonction"
                                            placeHolder="Fonction"
                                            enableSearch={true}
                                            options={otherFunctions}
                                            {...this.connectSelectOrText(
                                                "sector",
                                                "sectorFunction"
                                            )}
                                        />
                                    </Box>
                                    {inscription.sector.sectorFunction ===
                                        "other" && (
                                        <Box>
                                            <FormField className="input-full-width">
                                                <TextInput
                                                    placeHolder="Nom de la fonction"
                                                    {...this.connectText(
                                                        "sector",
                                                        "sectorFunctionOther"
                                                    )}
                                                />
                                            </FormField>
                                        </Box>
                                    )}
                                </React.Fragment>
                            )}
                        </Box>
                        <Box pad="medium">
                            <Heading tag="h3">Addresse</Heading>
                            <Box>
                                <Box direction="row">
                                    <FormField>
                                        <TextInput
                                            placeHolder="Adresse"
                                            {...this.connectText(
                                                "sector",
                                                "address:street"
                                            )}
                                        />
                                    </FormField>
                                    <FormField>
                                        <TextInput
                                            placeHolder="BP/CS"
                                            {...this.connectText(
                                                "sector",
                                                "address:cs"
                                            )}
                                        />
                                    </FormField>
                                </Box>
                                <Box direction="row">
                                    <FormField>
                                        <TextInput
                                            placeHolder="Code postal"
                                            {...this.connectText(
                                                "sector",
                                                "address:zipcode"
                                            )}
                                        />
                                    </FormField>
                                    <FormField>
                                        <TextInput
                                            placeHolder="Commune"
                                            {...this.connectText(
                                                "sector",
                                                "address:city"
                                            )}
                                        />
                                    </FormField>
                                    <FormField>
                                        <TextInput
                                            placeHolder="Cedex"
                                            {...this.connectText(
                                                "sector",
                                                "address:cedex"
                                            )}
                                        />
                                    </FormField>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Heading tag="h2">Options</Heading>
                    <Box className="form-group" pad="medium">
                        {this.props.config.days.map((day) => (
                            <Box pad="medium" key={day.key}>
                                <Box direction="row">
                                    <Heading style={{ flex: 1 }} tag="h3">
                                        {day.labelDay}
                                    </Heading>
                                    <Box
                                        style={{ flex: 2 }}
                                        direction="row"
                                        className="checkbox-wrapper"
                                    >
                                        <RadioButton
                                            label="Présent"
                                            name={`${day.key}-presence`}
                                            id={`${day.key}-present`}
                                            checked={inscription.registration.presenceDay.includes(
                                                day.key
                                            )}
                                            onChange={this.handleDayPresenceChange(
                                                day.key,
                                                true
                                            )}
                                        />
                                        <RadioButton
                                            label="Absent"
                                            name={`${day.key}-presence`}
                                            id={`${day.key}-absent`}
                                            checked={
                                                !inscription.registration.presenceDay.includes(
                                                    day.key
                                                )
                                            }
                                            onChange={this.handleDayPresenceChange(
                                                day.key,
                                                false
                                            )}
                                        />
                                    </Box>
                                </Box>
                                <Box>
                                    {day.meal && (
                                        <React.Fragment>
                                            <Box direction="row">
                                                <Heading
                                                    style={{ flex: 1 }}
                                                    tag="h4"
                                                >
                                                    Déjeuner
                                                </Heading>
                                                <Box
                                                    style={{ flex: 2 }}
                                                    direction="row"
                                                    className="checkbox-wrapper"
                                                >
                                                    <RadioButton
                                                        label="Réservation"
                                                        name="meal"
                                                        id="meal-reservation"
                                                        checked={
                                                            inscription
                                                                .registration
                                                                .meal ===
                                                            "réservation"
                                                        }
                                                        onChange={() =>
                                                            this.props.changeDetailsField(
                                                                [
                                                                    "registration",
                                                                    "meal",
                                                                ],
                                                                "réservation"
                                                            )
                                                        }
                                                    />
                                                    {/* <RadioButton label="Liste d'attente" name='meal' id='meal-pending' checked={inscription.registration.meal === 'pending'} onChange={() => this.props.changeDetailsField(['registration', 'meal'], 'pending')} /> */}
                                                    <RadioButton
                                                        label="Salon de restauration"
                                                        name="meal"
                                                        id="meal-foodtruck"
                                                        checked={
                                                            inscription
                                                                .registration
                                                                .meal ===
                                                            "foodtruck"
                                                        }
                                                        onChange={() =>
                                                            this.props.changeDetailsField(
                                                                [
                                                                    "registration",
                                                                    "meal",
                                                                ],
                                                                "foodtruck"
                                                            )
                                                        }
                                                    />
                                                    <RadioButton
                                                        label="Aucun"
                                                        name="meal"
                                                        id="meal-none"
                                                        checked={
                                                            inscription
                                                                .registration
                                                                .meal === "none"
                                                        }
                                                        onChange={() =>
                                                            this.props.changeDetailsField(
                                                                [
                                                                    "registration",
                                                                    "meal",
                                                                ],
                                                                "none"
                                                            )
                                                        }
                                                    />
                                                </Box>
                                            </Box>
                                            <Box
                                                direction="row"
                                                margin={{ bottom: "small" }}
                                            >
                                                <Heading
                                                    style={{ flex: 1 }}
                                                    tag="h4"
                                                >
                                                    Paiement
                                                </Heading>
                                                <Box
                                                    style={{ flex: 2 }}
                                                    direction="column"
                                                    className="checkbox-wrapper"
                                                >
                                                    {day.meal.payments.map(
                                                        (payment) => (
                                                            <RadioButton
                                                                key={payment}
                                                                label={payment}
                                                                name="meal-payment"
                                                                id={`meal-${payment}`}
                                                                checked={
                                                                    inscription
                                                                        .registration
                                                                        .mealPayment ===
                                                                    payment
                                                                }
                                                                onChange={() =>
                                                                    this.props.changeDetailsField(
                                                                        [
                                                                            "registration",
                                                                            "mealPayment",
                                                                        ],
                                                                        payment
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </Box>
                                            </Box>
                                        </React.Fragment>
                                    )}
                                    {day.events.map((event) => {
                                        const key = event.id;
                                        const value =
                                            inscription.registration.events[
                                                key
                                            ];
                                        return (
                                            <React.Fragment key={key}>
                                                <Box direction="row">
                                                    <Heading
                                                        style={{ flex: 1 }}
                                                        tag="h4"
                                                    >
                                                        {event.title}
                                                    </Heading>
                                                    <Box
                                                        style={{ flex: 2 }}
                                                        direction="row"
                                                        className="checkbox-wrapper"
                                                    >
                                                        <RadioButton
                                                            label="Oui"
                                                            name={key}
                                                            id={`${key}-present`}
                                                            checked={
                                                                value ===
                                                                "present"
                                                            }
                                                            onChange={() =>
                                                                this.props.changeDetailsField(
                                                                    [
                                                                        "registration",
                                                                        "events",
                                                                        key,
                                                                    ],
                                                                    "present"
                                                                )
                                                            }
                                                        />
                                                        {/* <RadioButton label="Liste d'attente" name={key} id={`${key}-pending`} checked={value === 'pending'} onChange={() => this.props.changeDetailsField(['registration', 'events', key], 'pending')} /> */}
                                                        <RadioButton
                                                            label="Non"
                                                            name={key}
                                                            id={`${key}-absent`}
                                                            checked={
                                                                value ===
                                                                "absent"
                                                            }
                                                            onChange={() =>
                                                                this.props.changeDetailsField(
                                                                    [
                                                                        "registration",
                                                                        "events",
                                                                        key,
                                                                    ],
                                                                    "absent"
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                            </React.Fragment>
                                        );
                                    })}
                                </Box>
                            </Box>
                        ))}
                        <Box pad="medium">
                            <Heading tag="h3">
                                Addresse de facturation (si différente)
                            </Heading>
                            <Box>
                                <Box direction="row">
                                    <FormField>
                                        <TextInput
                                            placeHolder="Adresse"
                                            {...this.connectText(
                                                "registration",
                                                "invoicing-address:street"
                                            )}
                                        />
                                    </FormField>
                                    <FormField>
                                        <TextInput
                                            placeHolder="BP/CS"
                                            {...this.connectText(
                                                "registration",
                                                "invoicing-address:cs"
                                            )}
                                        />
                                    </FormField>
                                </Box>
                                <Box direction="row">
                                    <FormField>
                                        <TextInput
                                            placeHolder="Code postal"
                                            {...this.connectText(
                                                "registration",
                                                "invoicing-address:zipcode"
                                            )}
                                        />
                                    </FormField>
                                    <FormField>
                                        <TextInput
                                            placeHolder="Commune"
                                            {...this.connectText(
                                                "registration",
                                                "invoicing-address:city"
                                            )}
                                        />
                                    </FormField>
                                    <FormField>
                                        <TextInput
                                            placeHolder="Cedex"
                                            {...this.connectText(
                                                "registration",
                                                "invoicing-address:cedex"
                                            )}
                                        />
                                    </FormField>
                                </Box>
                            </Box>
                        </Box>
                        <Box direction="row" pad="medium">
                            <Heading style={{ flex: 1 }} tag="h3">
                                Attestation de présence
                            </Heading>
                            <Box
                                style={{ flex: 2 }}
                                direction="row"
                                className="checkbox-wrapper"
                            >
                                <RadioButton
                                    label="Oui"
                                    name="attendance-certificate"
                                    id="attendance-certificate-yes"
                                    checked={
                                        inscription.registration
                                            .attendanceCertificate
                                    }
                                    onChange={() =>
                                        this.props.changeDetailsField(
                                            [
                                                "registration",
                                                "attendanceCertificate",
                                            ],
                                            true
                                        )
                                    }
                                />
                                <RadioButton
                                    label="Non"
                                    name="attendance-certificate"
                                    id="attendance-certificate-no"
                                    checked={
                                        !inscription.registration
                                            .attendanceCertificate
                                    }
                                    onChange={() =>
                                        this.props.changeDetailsField(
                                            [
                                                "registration",
                                                "attendanceCertificate",
                                            ],
                                            false
                                        )
                                    }
                                />
                            </Box>
                        </Box>
                        <Box direction="row" pad="medium">
                            <Heading style={{ flex: 1 }} tag="h3">
                                Inscription valide
                            </Heading>
                            <Box
                                style={{ flex: 2 }}
                                direction="row"
                                className="checkbox-wrapper"
                            >
                                <RadioButton
                                    label="Oui"
                                    name="valid"
                                    id="valid-yes"
                                    checked={inscription.valid}
                                    onChange={() =>
                                        this.props.changeDetailsField(
                                            ["valid"],
                                            true
                                        )
                                    }
                                />
                                <RadioButton
                                    label="Non"
                                    name="valid"
                                    id="valid-no"
                                    checked={!inscription.valid}
                                    onChange={() =>
                                        this.props.changeDetailsField(
                                            ["valid"],
                                            false
                                        )
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default InscriptionComponent;
