import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { login } from '../../modules/authentication';
import LoginComponent from '../../components/login';
import { isLoggedIn } from '../../selectors/authentication';

const mapStateToProps = state => {
    const authentication = state.authentication;

    return {
        isLoggedIn: isLoggedIn(authentication),
        message: authentication.message,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    login,
}, dispatch);


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginComponent);
