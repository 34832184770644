import React from 'react';
import { Box, Button, Heading, Meter, Legend } from 'grommet';
import { CenteredValue } from './CenteredValue';

import '../../index.scss';

class DashboardComponent extends React.Component {
    UNSAFE_componentWillMount() {
        this.props.loadCount();
        this.props.listenCount();
    }

    render() {
        let registrationBalanceBlocks = '';

        const rolesBlock = (this?.props?.count?.loaded && this?.props?.count?.data?.roles)
            ? (
                <Box margin="small" flex={false} size="medium" className="event-card" pad="medium">
                    <Heading tag="h3">Inscriptions</Heading>
                    <Legend series={[
                        { "label": this.props.count.data.roles[0]._id, "value": this.props.count.data.roles[0].count, "colorIndex": "neutral-2" },
                        { "label": this.props.count.data.roles[1]._id, "value": this.props.count.data.roles[1].count, "colorIndex": "neutral-3" },
                    ]}
                        total={true}
                    />
                    <Box align='center' margin={{ top: 'medium' }}>
                        <Meter
                            series={[
                                { value: this.props.count.data.roles[0].count, colorIndex: 'neutral-2' },
                                { value: this.props.count.data.roles[1].count, colorIndex: 'neutral-3' }
                            ]}
                            max={this.props.count.data.roles[0].count + this.props.count.data.roles[1].count}
                            type='arc'
                            vertical={false}
                            stacked={true}
                            legend={true}
                            label={<CenteredValue value={this.props.count.data.roles[0].count + ' + ' + this.props.count.data.roles[1].count + ' = ' + (this.props.count.data.roles[0].count + this.props.count.data.roles[1].count)} />}
                        />
                    </Box>
                </Box>
            )
            : null;

        const mealBlock = this.props.count.loaded
            ? (
                <Box margin="small" flex={false} size="medium" className="event-card" pad="medium">
                    <Heading tag="h3">Déjeuner</Heading>
                    <Legend
                        series={[
                            { "label": "Réservations", "value": this.props.count.data.meal.registrationsCount.reservation, "colorIndex": "neutral-4" },
                            { "label": "Prévisions Salon de restauration", "value": this.props.count.data.meal.registrationsCount.foodtruck, "colorIndex": "none" },
                        ]}
                        total={true}
                    />
                    {/* <div>Inscriptions en attente : <b>{this.props.count.data.meal.registrationsCount.pending}</b></div> */}
                    <Box align='center' margin={{ top: 'medium' }}>
                        <Meter
                            colorIndex='neutral-4'
                            max={this.props.count.data.meal.maximumRegistrationsCount}
                            value={this.props.count.data.meal.registrationsCount.reservation}
                            type='arc'
                            label={<CenteredValue value={this.props.count.data.meal.registrationsCount.reservation + '/' + this.props.count.data.meal.maximumRegistrationsCount} />}
                        />
                    </Box>
                </Box>
            )
            : null;

        if (this.props.count.loaded) {
            registrationBalanceBlocks = this.props.count.data.events.map(event => {

                const meterLabel = (<CenteredValue value={event.registrationsCount.present + '/' + event.maximumRegistrationsCount} />)

                return (
                    <Box key={event.id} margin="small" flex={false} size="large" className="event-card" pad="medium">
                        <Heading tag="h3">{event.title}</Heading>
                        <Legend
                            series={[
                                { "label": "Inscriptions", "value": event.registrationsCount.present, "colorIndex": "neutral-4" },
                                { "label": "Places restantes", "value": event.maximumRegistrationsCount - event.registrationsCount.present, "colorIndex": "unset" },
                            ]}
                        />
                        <Box align='center' margin={{ top: 'medium' }}>
                            <Meter
                                colorIndex='neutral-4'
                                max={event.maximumRegistrationsCount}
                                value={event.registrationsCount.present}
                                type='arc'
                                label={meterLabel}
                            />
                        </Box>
                    </Box>
                );
            });
        }

        const message = this.props.csv.error ? (<Box flex={true} justify='center' direction='row' margin="medium">Une erreur s'est produite lors de l'export.</Box>) : null;

        return (<div>
            <Box justify='center' direction='row' margin="medium">
                <Button primary={!this.props.csv.url} className="flex110" style={{ 'marginRight': '10px' }} label="Exporter au format CSV" onClick={() => this.props.exportCSV(this.props.token)} />
                <Button primary={!!this.props.csv.url} className="flex110" style={{ 'marginLeft': '10px' }} label="Télécharger le fichier CSV" href={this.props.csv.url} />
            </Box>
            {message}

            <Box direction="row" basis="full" wrap={true} justify="center" full="horizontal">{rolesBlock}{mealBlock}{registrationBalanceBlocks}</Box>
        </div>);
    }
}

export default DashboardComponent;
