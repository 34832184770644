import React from 'react';
import StepComponent from '../step';
import { RadioButton, FormField, Box, TextInput, Heading } from 'grommet';
import { isFieldValid, allValid } from '../../helpers/validation';
import WarningMessage from '../fields/warning-message';

import '../form/index.scss';
import './index.scss';

class CoordinatesComponent extends StepComponent {

    getFieldValidations(checkDirty = false) {
        return {
            gender: isFieldValid({ propName: 'gender', value: this.props.gender, type: 'string', mandatory: true }, checkDirty, this.props.dirty),
            lastname: isFieldValid({ propName: 'lastname', value: this.props.lastname, type: 'string', mandatory: true }, checkDirty, this.props.dirty),
            firstname: isFieldValid({ propName: 'firstname', value: this.props.firstname, type: 'string', mandatory: true }, checkDirty, this.props.dirty),
            email: isFieldValid({ propName: 'email', value: this.props.email, type: 'email', mandatory: true }, checkDirty, this.props.dirty),
            emailConfirmation: isFieldValid({ propName: 'emailConfirmation', value: this.props.emailConfirmation, type: 'emailConfirmation', compare: this.props.email, mandatory: true }, checkDirty, this.props.dirty),
            phone: isFieldValid({ propName: 'phone', value: this.props.phone, type: 'match-regex', compare: /^\d{10}$/, mandatory: false }, checkDirty, this.props.dirty),
        };
    }


    isValid() {
        return allValid(this.getFieldValidations(true));
    }

    render() {
        return (
            <div>
                <Heading tag="h2" className="form-group-title">Renseignez vos coordonnées</Heading>
                <Box className="form-group" pad="medium">
                    <Box direction="row" className="checkbox-wrapper">
                        <WarningMessage when={!this.getFieldValidations().gender} severity="error" message="Ce champ est obligatoire" />
                        <RadioButton label="Madame" name="inlineRadioOptions" id="genderFemale" {...this.connectRadio('gender', 'female')} />
                        <RadioButton label="Monsieur" name="inlineRadioOptions" id="genderMale" {...this.connectRadio('gender', 'male')} />
                    </Box>
                    <Box direction="row">
                        <WarningMessage when={!this.getFieldValidations().firstname} severity="error" message="Ce champ est obligatoire" />
                        <FormField>
                            <TextInput placeHolder="Prénom" {...this.connectText('firstname', { maxLength: 24 })} className={this.getFieldValidations().firstname ? 'valid' : 'invalid'} />
                        </FormField>
                        <WarningMessage when={!this.getFieldValidations().lastname} severity="error" message="Ce champ est obligatoire" />
                        <FormField>
                            <TextInput placeHolder="Nom" {...this.connectText('lastname', { maxLength: 20 })} className={this.getFieldValidations().lastname ? 'valid' : 'invalid'} />
                        </FormField>
                    </Box>
                    <Box direction="row">
                        <WarningMessage when={!this.getFieldValidations().email} severity="error" message="Ce champ est obligatoire et doit être un e-mail valide" />
                        <FormField>
                            <TextInput type="email" placeHolder="E-mail" {...this.connectText('email')} className={this.getFieldValidations().email ? 'valid' : 'invalid'} />
                        </FormField>
                        <WarningMessage when={!this.getFieldValidations().emailConfirmation} severity="error" message="Ce champ est obligatoire, doit être un e-mail valide et correspondre à l'adresse e-mail" />
                        <FormField>
                            <TextInput type="email" placeHolder="Confirmation e-mail" {...this.connectText('emailConfirmation')} className={this.getFieldValidations().emailConfirmation ? 'valid' : 'invalid'} />
                        </FormField>
                    </Box>
                    <Box className='box50' direction="row">
                        <WarningMessage when={!this.getFieldValidations().phone} severity="error" message="Ce champ doit être un numéro de téléphone valide au format 0123456789" />
                        <FormField>
                            <TextInput placeHolder="Téléphone" {...this.connectText('phone')} className={this.getFieldValidations().phone ? 'valid' : 'invalid'} />
                        </FormField>
                    </Box>
                </Box>
                <Box pad={{ horizontal: "medium" }}>
                    <p>Dans le respect du traitement des données à caractère personnel (RGPD)<br />(Cf. Article 17 du règlement intérieur du Carrefour des communes en fin de questionnaire).</p>
                </Box>
            </div>
        );
    }
}

export default CoordinatesComponent;
