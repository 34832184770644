import openSocket from 'socket.io-client';

export const LOADING_IN_PROGRESS = 'balance/LOADING_IN_PROGRESS';
export const LOADING_SUCCESS = 'balance/LOADING_SUCCESS';
export const LOADING_FAILURE = 'balance/LOADING_FAILURE';
export const REALTIME_UPDATE_RECEIVED = 'balance/REALTIME_UPDATE_RECEIVED';

const initialState = {
    loaded: false,
    data: null
};

export const loadBalance = () => async dispatch => {
    dispatch({
        type: LOADING_IN_PROGRESS,
    });

    const response = await fetch(process.env.REACT_APP_API_HOST + '/registration/balance')
    const json = await response.json();
    dispatch({
        type: LOADING_SUCCESS,
        payload: json,
    });
}

export const listenBalance = () => async dispatch => {
    const socket = openSocket(process.env.REACT_APP_API_HOST);
    socket.on('balance:updated', data => {
        dispatch({
            type: REALTIME_UPDATE_RECEIVED,
            payload: data
        });
    });
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_SUCCESS: {
            return {
                loaded: true,
                data: action.payload
            };
        }

        case REALTIME_UPDATE_RECEIVED: {
            return {
                ...state,
                data: action.payload,
            };
        }

        default: 
            return state;
    }
}

export default reducer;
