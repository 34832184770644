import React from 'react';
import { TextInput, Table, TableRow, Button } from 'grommet';
import DocumentPdfIcon from 'grommet/components/icons/base/DocumentPdf';

class InscriptionsComponent extends React.Component {
    state = {
        searches: {
            id: '',
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            structure: '',
        }
    }

    UNSAFE_componentWillMount() {
        this.props.fetchList(this.props.token)
    }

    connectSearch = (field) => ({
        value: this.state.searches[field],
        onDOMChange: (event) => { this.setState({ searches: { ...this.state.searches, [field]: event.target.value } }) }
    })

    filterSearch = (field) => string => {
        const search = this.state.searches[field];
        return !search || ((new RegExp(`^.*${search}.*$`, 'gmi')).test(`${string}`));
    }

    render() {
        return <Table scrollable={false} selectable={true} className="inscriptions">
            <thead>
                <tr>
                    <th><TextInput className="pl-0" {...this.connectSearch('role')} placeHolder="Type d'inscription" /></th>
                    <th><TextInput className="pl-0" {...this.connectSearch('firstname')} placeHolder='Prénom' /></th>
                    <th><TextInput className="pl-0" {...this.connectSearch('lastname')} placeHolder='Nom' /></th>
                    <th><TextInput className="pl-0" {...this.connectSearch('email')} placeHolder='E-mail' /></th>
                    <th><TextInput className="pl-0" {...this.connectSearch('phone')} placeHolder='Téléphone' /></th>
                    <th><TextInput className="pl-0" {...this.connectSearch('structure')} placeHolder='Collectivité / Structure' /></th>
                    <th>Badge</th>
                </tr>
            </thead>
            <tbody>
                {
                    this.props.inscriptions.filter(item => {
                        return this.filterSearch('role')(item.home.role)
                            && this.filterSearch('firstname')(item.coordinates.firstname)
                            && this.filterSearch('lastname')(item.coordinates.lastname)
                            && this.filterSearch('email')(item.coordinates.email)
                            && this.filterSearch('phone')(item.coordinates.phone)
                            && this.filterSearch('structure')(item.sector.sectorStructure)
                    }).map(item => (
                        <TableRow key={item._id} onClick={() => this.props.history.push(`/administration/inscription/${item._id}`)}>
                            <td className="capitalize">{item.home.role}</td>
                            <td>{item.coordinates.firstname}</td>
                            <td>{item.coordinates.lastname.toUpperCase()}</td>
                            <td>{item.coordinates.email}</td>
                            <td>{item.coordinates.phone}</td>
                            <td>{item.sector.sectorStructure.toUpperCase()}</td>
                            <td><Button type="button" target="_blank" href={`${process.env.REACT_APP_API_HOST}/badge/${item._id}/pdf`} onClick={(e) => e.stopPropagation()} icon={<DocumentPdfIcon />}></Button></td>
                        </TableRow>
                    ))}
            </tbody>
        </Table>
    }
}

export default InscriptionsComponent;
