import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchList } from '../../modules/inscriptions';
import InscriptionsComponent from '../../components/inscriptions';
import { withRouter } from 'react-router'

const mapStateToProps = state => ({ 
    inscriptions: state.inscriptions.list,
    token: state.authentication.token,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchList,
}, dispatch);


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(InscriptionsComponent));
