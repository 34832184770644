import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import form from './form';
import config from './config';
import authentication from './authentication';
import balance from './balance';
import dashboard from './dashboard';
import inscriptions from './inscriptions';

export const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    form,
    config,
    authentication,
    balance,
    dashboard,
    inscriptions
});

export default createRootReducer;
