export const LOADING_IN_PROGRESS = 'config/LOADING_IN_PROGRESS';
export const LOADING_SUCCESS = 'config/LOADING_SUCCESS';
export const LOADING_FAILURE = 'config/LOADING_FAILURE';

const initialState = {
    loaded: false,
    data: {
        days: [],
        sectors: {
            public: {
                townHall: {
                    townships: [],
                    functions: [],
                },
                community: {
                    epcis: [],
                    functions: [],
                },
                other: {
                    services: [],
                }
            },
            other: []
        }
    }
};

export const loadConfig = () => async dispatch => {
    dispatch({
        type: LOADING_IN_PROGRESS,
    });

    const response = await fetch(process.env.REACT_APP_API_HOST + '/config')
    const json = await response.json();
    dispatch({
        type: LOADING_SUCCESS,
        payload: json,
    });
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_SUCCESS: {
            return {
                loaded: true,
                data: action.payload
            };
        }

        default: 
            return state;
    }
}

export default reducer;
