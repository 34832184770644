import React from 'react';
import { Box, TextInput, PasswordInput, Button, FormField, Image, Heading } from 'grommet';
import { Redirect } from 'react-router-dom';

import '../../index.scss';

class LoginComponent extends React.Component {

    constructor() {
        super();
        this.state = {
            username: null,
            password: null
        }
    }

    usernameChange(event) {
        this.setState({ username: event.target.value });
    }

    passwordChange(event) {
        this.setState({ password: event.target.value });
    }

    render() {
        if (this.props.isLoggedIn) return (<Redirect exact push={true} to="/administration/dashboard" />);

        const errorBox = this.props.message ? (<Box>{this.props.message}</Box>) : null;
        return (
            <Box full={true} align="center" colorIndex="light-2" pad="medium">
                <Image size="small" alt="flyer" src="/assets/img/flyer.png" />
                <Box size="medium" align="center" margin="medium">
                    <Heading tag="h2">Connexion</Heading>
                    <FormField >
                        <TextInput placeHolder="Utilisateur" onDOMChange={event => this.usernameChange(event)} />
                    </FormField>
                    <FormField>
                        <PasswordInput placeholder="Mot de passe" onChange={event => this.passwordChange(event)} />
                    </FormField>
                    <Button style={{ 'margin': '11px 0 11px 0' }} label="Connexion" primary={true} fill={true} onClick={() => this.props.login(this.state.username, this.state.password)} />
                    {errorBox}
                </Box>
            </Box>
        );
    }
}

export default LoginComponent;