export const LOGIN_IN_PROGRESS = 'authentication/LOGIN_IN_PROGRESS';
export const LOGIN_SUCCESS = 'authentication/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'authentication/LOGIN_FAILURE';
export const LOGOUT = 'authentication/LOGOUT';

const initialState = {
    token: null,
    message: null,
};

const messages = {
    401: 'Identifiants incorrects.',
    403: 'Identifiants incorrects.',
    500: 'Une erreur système est survenue.',
};

export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT,
    });
}

export const login = (username, password) => async dispatch => {
    dispatch({
        type: LOGIN_IN_PROGRESS,
    });

    const response = await fetch(
        process.env.REACT_APP_API_HOST + '/auth/login',
        {
            method: 'POST', 
            body: JSON.stringify({username, password}),
            headers: {
                'Content-Type': 'application/json'
            },
        }
    )

    if (!response.ok) {
        dispatch({
            type: LOGIN_FAILURE,
            payload: messages[response.status],
        });
    } else {
        const json = await response.json();
        dispatch({
            type: LOGIN_SUCCESS,
            payload: json,
        });
    }
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {

        case LOGIN_SUCCESS: {
            return {
                token: action.payload.token,
                exp: action.payload.exp,
                message: null,
            };
        }

        case LOGIN_FAILURE: {
            return {
                token: null,
                message: action.payload,
            };
        }

        case LOGOUT: {
            return {
                token: null,
                exp: null,
                message: null,
            };
        }

        default: 
            return state;
    }
}

export default reducer;
