import openSocket from 'socket.io-client';

export const COUNT_LOADING_IN_PROGRESS = 'dashboard/COUNT_LOADING_IN_PROGRESS';
export const COUNT_LOADING_SUCCESS = 'dashboard/COUNT_LOADING_SUCCESS';
export const COUNT_LOADING_FAILURE = 'dashboard/COUNT_LOADING_FAILURE';
export const COUNT_REALTIME_UPDATE_RECEIVED = 'dashboard/COUNT_REALTIME_UPDATE_RECEIVED';

export const CSV_EXPORT_IN_PROGRESS = 'dashboard/CSV_EXPORT_IN_PROGRESS';
export const CSV_EXPORT_SUCCESS = 'dashboard/CSV_EXPORT_SUCCESS';
export const CSV_EXPORT_FAILURE = 'dashboard/CSV_EXPORT_FAILURE';

const initialState = {
    count: {
        loaded: false,
        data: null
    },
    csv: {
        url: null,
        message: null,
    }
};

export const loadCount = () => async dispatch => {
    dispatch({
        type: COUNT_LOADING_IN_PROGRESS,
    });

    const response = await fetch(process.env.REACT_APP_API_HOST + '/registration/count')
    const json = await response.json();
    dispatch({
        type: COUNT_LOADING_SUCCESS,
        payload: json,
    });
}

export const listenCount = () => async dispatch => {
    const socket = openSocket(process.env.REACT_APP_API_HOST);
    socket.on('count:updated', data => {
        dispatch({
            type: COUNT_REALTIME_UPDATE_RECEIVED,
            payload: data
        });
    });
}

export const exportCSV = token => async dispatch => {
    dispatch({
        type: COUNT_LOADING_IN_PROGRESS,
    });

    const headers = {'Authorization': 'Bearer ' + token};

    try {
        const response = await fetch(process.env.REACT_APP_API_HOST + '/registration/export-data', {
            method: 'GET',
            headers,
        });
        const json = await response.json();
        dispatch({
            type: CSV_EXPORT_SUCCESS,
            payload: process.env.REACT_APP_API_HOST + json.url + '?authorization=' + token,
        });
    } catch(_err) {
        dispatch({
            type: CSV_EXPORT_FAILURE,
        });
    };
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case COUNT_LOADING_SUCCESS: {
            return {
                ...state,
                count: {
                    loaded: true,
                    data: action.payload
                }
            };
        }

        case COUNT_REALTIME_UPDATE_RECEIVED: {
            return {
                ...state,
                count: {
                    ...state.count,
                    data: action.payload,
                },
            };
        }

        case CSV_EXPORT_SUCCESS: {
            return {
                ...state,
                csv: {
                    url: action.payload,
                    error: false,
                },
            };
        }

        case CSV_EXPORT_FAILURE: {
            return {
                ...state,
                csv: {
                    url: null,
                    error: true,
                },
            };
        }

        default: 
            return state;
    }
}

export default reducer;
