import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadCount, listenCount, exportCSV } from '../../modules/dashboard';
import DashboardComponent from '../../components/dashboard';
import { withRouter } from 'react-router'

const mapStateToProps = state => state.dashboard;

const mapDispatchToProps = dispatch => bindActionCreators({
    loadCount,
    listenCount,
    exportCSV,
}, dispatch);


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardComponent));
