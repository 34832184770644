import React from 'react';
import { Route, Redirect } from 'react-router-dom'

import SidebarComponent from '../sidebar';
import Split from 'grommet/components/Split';
import LogoutIcon from 'grommet/components/icons/base/Logout';

class PrivateRoute extends React.Component {
    render() {
        const {component: Component, ...rest} = this.props;
        return (
            <Split flex='right'>
                <SidebarComponent 
                    title={`CCF ${this.props.config.data.year}`} 
                    routes={[
                        { path: '/administration/dashboard', label: "Vue d'ensemble", key: "Vue d'ensemble" },
                        { path: '/administration/inscriptions', label: 'Inscriptions', key: 'Inscriptions'  }
                    ]} 
                    actions={[
                        { icon: <LogoutIcon />, label: 'Déconnexion', onClick: this.props.logout, key: 'Déconnexion' }
                    ]}
                />
                <Route
                    {...rest}
                    render={props => {
                        return rest.isLoggedIn ? 
                            <Component {...rest} /> : 
                            <Redirect to={{ pathname: '/login', state: { from: props.location }}} />
                        ;
                    }}
                />
            </Split>
        )
    }
}

export default PrivateRoute