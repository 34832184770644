import React from 'react';
import StepComponent from '../step';
import { FormField, Box, TextInput, Heading } from 'grommet';
import { isFieldValid, allValid } from '../../helpers/validation';
import WarningMessage from '../fields/warning-message';

export const SECTOR_FUNCTION = 'sectorFunction';
export const SECTOR_STRUCTURE = 'sectorStructure';
export const ADDRESS_STREET = 'address:street';
export const ADDRESS_CS = 'address:cs';
export const ADDRESS_ZIPCODE = 'address:zipcode';
export const ADDRESS_CITY = 'address:city';
export const ADDRESS_CEDEX = 'address:cedex';

class StructureComponent extends StepComponent {

    getFieldValidations(checkDirty = false) {
        return {
            [SECTOR_STRUCTURE]: isFieldValid({propName: SECTOR_STRUCTURE, value: this.props[SECTOR_STRUCTURE], type: 'string', mandatory: true}, checkDirty, this.props.dirty),
            [SECTOR_FUNCTION]: isFieldValid({propName: SECTOR_FUNCTION, value: this.props[SECTOR_FUNCTION], type: 'string', mandatory: true}, checkDirty, this.props.dirty),
            [ADDRESS_STREET]: isFieldValid({propName: ADDRESS_STREET, value: this.props[ADDRESS_STREET], type: 'string', mandatory: true}, checkDirty, this.props.dirty),
            [ADDRESS_ZIPCODE]: isFieldValid({propName: ADDRESS_ZIPCODE, value: this.props[ADDRESS_ZIPCODE], type: 'string', mandatory: true}, checkDirty, this.props.dirty),
            [ADDRESS_CITY]: isFieldValid({propName: ADDRESS_CITY, value: this.props[ADDRESS_CITY], type: 'string', mandatory: true}, checkDirty, this.props.dirty),
        };
    }

    isValid() {
        return allValid(this.getFieldValidations(true));
    }

    addressForm() {
        return (
            <Box>
                <Box direction="row">
                    <Box size="xlarge" direction="row" align="center">
                        <WarningMessage when={!this.getFieldValidations()['address:street']} severity="error" message="Ce champ est obligatoire" />
                        <FormField>
                            <TextInput placeHolder="Adresse" {...this.connectText(ADDRESS_STREET)} />
                        </FormField>
                    </Box>
                    <Box direction="row">
                        <FormField>
                            <TextInput placeHolder="BP/CS" {...this.connectText(ADDRESS_CS)} />
                        </FormField>
                    </Box>
                </Box>
                <Box direction="row">
                    <Box size="auto" direction="row">
                        <WarningMessage when={!this.getFieldValidations()['address:zipcode']} severity="error" message="Ce champ est obligatoire" />
                        <FormField>
                            <TextInput placeHolder="Code postal" {...this.connectText(ADDRESS_ZIPCODE)} />
                        </FormField>
                    </Box>
                    <Box size="medium" direction="row">
                        <WarningMessage when={!this.getFieldValidations()['address:city']} severity="error" message="Ce champ est obligatoire" />
                        <FormField>
                            <TextInput placeHolder="Commune" {...this.connectText(ADDRESS_CITY)} />
                        </FormField>
                    </Box>
                    <Box size="auto" direction="row">
                        <FormField>
                            <TextInput placeHolder="Cedex" {...this.connectText(ADDRESS_CEDEX)} />
                        </FormField>
                    </Box>
                </Box>
            </Box>
        );
    }

    addOtherField(optionArray) {
        optionArray.forEach((options) => {
            options.push({
                label: 'Autre...',
                value: 'other',
            });
        });
    }

    render () {
        return (
            <div>
                <div>
                    <Heading tag="h2" className="form-group-title">Renseignez les coordonnées de votre structure</Heading>
                    <Box className="form-group" pad="medium">
                        <Box>
                            <WarningMessage when={!this.getFieldValidations()[SECTOR_STRUCTURE]} severity="error" message="Ce champ est obligatoire" />

                            <FormField className="input-full-width">
                                <TextInput placeHolder="Entrez le nom de votre structure" id="sectorPrivateCorporation" name="sectorPrivateCorporation" {...this.connectText(SECTOR_STRUCTURE, { maxLength: 41 })} />
                            </FormField>
                        </Box>
                        <Box>
                            <WarningMessage when={!this.getFieldValidations()[SECTOR_FUNCTION]} severity="error" message="Ce champ est obligatoire" />
                            <FormField className="input-full-width">
                                <TextInput placeHolder="Fonction" id="sectorPrivateFunction" name="sectorPrivateFunction" {...this.connectText(SECTOR_FUNCTION, { maxLength: 20 })} />
                            </FormField>
                        </Box>
                        {this.addressForm()}
                    </Box>
                </div>
            </div>
        );
    }
}

export default StructureComponent;
