import React from 'react';
import { Select, FormField, Box } from 'grommet';

class SelectOrText extends React.Component {

    constructor() {
        super();
        this.state = {
            value: '',
            options: []
        }
    }

    UNSAFE_componentWillMount() {
        let selectedValue = null;
        if (this.props.value) {
            selectedValue = this.props.options.find(item => item.value === this.props.value || item === this.props.value);
        }
        this.setState({
            options: this.props.options,
            value: selectedValue,
        });
    }

    onSelectChange(event) {
        this.setState({
            value: event.value,
        });
        this.props.onChange({ value: event.value.value || event.value });
    }

    onSelectBlur(e) {
        this.props.onBlur(e);
    }

    onSearch(event) {
        const search = event.target.value;
        if (!search.length) {
            this.setState({ options: this.props.options });
            return;
        }
        // regex ordered by weight
        const regexToFind = [
            new RegExp("^" + search, 'i'),
            new RegExp(search, 'i')
        ];
        const results = [];
        regexToFind.forEach(regex => {
            const res = this.props.options.filter(option => {
                if (typeof option === 'string') {
                    return option.match(regex) && results.indexOf(option) === -1;
                } else {
                    return option.label.match(regex) && results.indexOf(option) === -1;
                }
            });
            results.push(...res);
        });

        this.setState({
            options: results
        });
    }

    render() {
        let textInputField = '';
        let selectInputAttributes = {
            value: this.state.value,
            options: this.state.options,
            onChange: (event) => this.onSelectChange(event),
            placeHolder: this.props.placeHolder
        };
        if (this.props.enableSearch) {
            selectInputAttributes.onSearch = (event) => this.onSearch(event);
        }

        return (
            <Box>
                <FormField className="select-full-width">
                    <Select onBlur={(e) => this.onSelectBlur(e)} className="selectortext-select" {...selectInputAttributes} />
                </FormField>
                {textInputField}
            </Box>
        );
    }
}

export default SelectOrText;
