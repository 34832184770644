import { isEmail } from 'validator';

export const isFieldValid = (field, checkDirty, dirty) => {
    if (!checkDirty && !isDirty(field, dirty)) {
        return true;
    }

    if (!isMandatory(field)) {
        return false;
    }

    switch (field.type) {
        case 'string':
            return !field.value || typeof field.value === 'string';
        case 'email':
            return !field.value || isEmail(field.value);
        case 'emailConfirmation':
            return !field.value || (isEmail(field.value) && field.value === field.compare);
        case 'match-regex':
            return !field.value || field.value.match(field.compare);
        default:
            return false;
    }
}

export const allValid = (obj) => {
    for (const o in obj) {
        if (!obj[o]) return false;
    }
    return true;
}

const isDirty = (field, dirty) => {
    if(!dirty) return false;
    return dirty.indexOf(field.propName) >= 0;
}

const isMandatory = (field) => {
    return (field.value || !field.mandatory);
}
