export const FETCH_LIST_IN_PROGRESS = 'inscriptions/FETCH_LIST_IN_PROGRESS';
export const FETCH_LIST_SUCCESS = 'inscriptions/FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'inscriptions/FETCH_LIST_FAILURE';

export const FETCH_DETAILS_IN_PROGRESS = 'inscriptions/FETCH_DETAILS_IN_PROGRESS';
export const FETCH_DETAILS_SUCCESS = 'inscriptions/FETCH_DETAILS_SUCCESS';
export const FETCH_DETAILS_FAILURE = 'inscriptions/FETCH_DETAILS_FAILURE';

export const SAVE_IN_PROGRESS = 'inscriptions/SAVE_IN_PROGRESS';
export const SAVE_SUCCESS = 'inscriptions/SAVE_SUCCESS';
export const SAVE_FAILURE = 'inscriptions/SAVE_FAILURE';

export const DELETION_IN_PROGRESS = 'inscriptions/DELETION_IN_PROGRESS';
export const DELETION_SUCCESS = 'inscriptions/DELETION_SUCCESS';
export const DELETION_FAILURE = 'inscriptions/DELETION_FAILURE';

export const CHANGE_DETAILS_FIELD = 'inscriptions/CHANGE_DETAILS_FIELD'

export const fetchList = (token) => async dispatch => {
    const headers = {'Authorization': 'Bearer ' + token};

    dispatch({
        type: FETCH_LIST_IN_PROGRESS,
    });

    const response = await fetch(process.env.REACT_APP_API_HOST + '/registration', {
        method: 'GET',
        headers,
    });

    const json = await response.json();
    dispatch({
        type: FETCH_LIST_SUCCESS,
        payload: json,
    });
}

export const fetchDetails = (id, token) => async dispatch => {
    const headers = {'Authorization': 'Bearer ' + token};

    dispatch({
        type: FETCH_DETAILS_IN_PROGRESS,
    });

    const response = await fetch(process.env.REACT_APP_API_HOST + `/registration/${id}`, {
        method: 'GET',
        headers,
    });

    const json = await response.json();
    dispatch({
        type: FETCH_DETAILS_SUCCESS,
        payload: json,
    });
}

export const changeDetailsField = (keys, value) => async dispatch => {
    dispatch({
        type: CHANGE_DETAILS_FIELD,
        payload: {
            keys,
            value
        }
    });
}

export const saveInscription = (inscription, token) => async dispatch => {
    const headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token};

    dispatch({
        type: SAVE_IN_PROGRESS,
    });

    await fetch(`${process.env.REACT_APP_API_HOST}/registration/${inscription._id}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(inscription),
    });

    dispatch({
        type: SAVE_SUCCESS,
    });

    fetchDetails(inscription._id, token)(dispatch);
    fetchList(token)(dispatch);
}

export const deleteInscription = (inscription, token) => async dispatch => {
    const headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token};

    dispatch({
        type: DELETION_IN_PROGRESS,
    });

    await fetch(`${process.env.REACT_APP_API_HOST}/registration/${inscription._id}`, {
        method: 'DELETE',
        headers
    });

    dispatch({
        type: DELETION_SUCCESS,
    });

    fetchList(token)(dispatch);
}

const initialState = {
    list: [],
    saving: false,
    deleting: false,
    details: {
        coordinates: {
            gender: '',
            firstname: '',
            lastname: '',
            email: '',
            phone: ''
        },
        registration: {
            events: {},
            presenceDay: []
        },
        sector: {},
    }
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload
            }

        case FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                details: action.payload
            }

        case SAVE_IN_PROGRESS:
            return {
                ...state,
                saving: true,
            }

        case SAVE_SUCCESS:
            return {
                ...state,
                saving: false,
            }

        case SAVE_FAILURE:
            return {
                ...state,
                saving: false,
            }

        case DELETION_IN_PROGRESS:
            return {
                ...state,
                deleting: true,
            }

        case DELETION_SUCCESS:
            return {
                ...state,
                deleting: false,
            }

        case DELETION_FAILURE:
            return {
                ...state,
                deleting: false,
            }

        case CHANGE_DETAILS_FIELD:
            const { keys: [key, subkey, subsubkey], value } = action.payload;
            const update = !subkey ? { 
                [key]: value 
            } : !subsubkey ? { 
                [key]: {
                    ...(state.details[key]), 
                    [subkey]: value 
                } 
            } : { 
                [key]: { 
                    ...(state.details[key]), 
                    [subkey]: {
                        ...(state.details[key][subkey]), 
                        [subsubkey]: value
                    } 
                } 
            }
            return {
                ...state,
                details: {
                    ...state.details,
                    ...update,
                }
            }

        default: 
            return state;
    }
}

export default reducer;
