import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import PrivateRouteContainer from '../../containers/private-route';
import FormContainer from '../../containers/form'
import LoginContainer from '../../containers/login';
import DashboardContainer from '../../containers/dashboard'
import InscriptionsContainer from '../../containers/inscriptions';
import InscriptionContainer from '../../containers/inscription';

class AppComponent extends React.Component {
    UNSAFE_componentWillMount() {
        this.props.loadConfig()
    }

    render() {
        return (     
            <Switch>
                <Route exact path="/administration" render={() => <Redirect to='/administration/dashboard' />} />
                <PrivateRouteContainer exact path="/administration/dashboard" component={DashboardContainer} />
                <PrivateRouteContainer exact path="/administration/inscriptions" component={InscriptionsContainer} />
                <PrivateRouteContainer exact path="/administration/inscription/:id" component={InscriptionContainer} />
                <Route exact path="/login" component={LoginContainer} />
                <Route exact path="/" component={FormContainer} />
            </Switch>
        );
    }
}

export default AppComponent;
