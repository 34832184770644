import React from 'react';
import './step.scss';

export default class BreadcrumbStep extends React.Component {
    getIcon() {
        const state = this.props.state;
        const step = this.props.id;
        return (<img alt="" src={'/assets/img/' + step + '-' + state + '.png'} className="step-img" />);
    }

    getLabel() {
        return (<div className='label'>
            {this.props.label}
        </div>);
    }

    render() {
        const arrow = (<div className="arrow" >
            <img alt="" src="/assets/img/arrow.png" />
        </div>);

        return (
            <>
                {this.props.prependArrow ? arrow : ''}
                <div className="breadcrumb-step" style={{ opacity: this.props.state === 'off' ? .5 : 1 }}>
                    {this.getIcon()}
                    {this.getLabel()}
                </div>
            </>);
    }
}
