import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateStep,
        updateCompletedStep,
        updateValidity,
        updateHomeField,
        updateSectorField,
        updateCoordinatesField,
        updateRegistrationField,
        updateSummaryField,
        submit,
        resetForm,
        resetFormRequest,
        abortReset,
        blurField } from '../../modules/form';

import CoordinatesComponent from '../../components/coordinates';
import SectorComponent from '../../components/sector';
import RegistrationComponent from '../../components/registration';
import SummaryComponent from '../../components/summary';
import ValidationComponent from '../../components/validation';
import FormComponent from '../../components/form';
import HomeComponent from '../../components/home';
import { loadBalance, listenBalance } from '../../modules/balance'
import StructureComponent from '../../components/structure';

const steps = {
    home: {
        component: HomeComponent,
        updateField: 'updateHomeField',
        previous: null,
        next: 'coordinates',
    },
    coordinates: {
        component: CoordinatesComponent,
        updateField: 'updateCoordinatesField',
        previous: 'home',
        next: 'sector',
    },
    registration: {
        component: RegistrationComponent,
        updateField: 'updateRegistrationField',
        previous: 'sector',
        next: 'summary',
    },
    summary: {
        component: SummaryComponent,
        updateField: 'updateSummaryField',
        previous: 'registration',
        next: 'validation',
        submit: true,
        cancel: true
    },
    validation: {
        previous: null,
        component: ValidationComponent,
        reset: true
    },
};

const mapStateToProps = state => {
    return {
        ...state.form,
        balance: state.balance.data,
        config: state.config.data,
        display: state.config.loaded && state.balance.loaded,
        steps,
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({
    updateStep,
    updateCompletedStep,
    updateValidity,
    updateHomeField,
    updateCoordinatesField,
    updateSectorField,
    updateRegistrationField,
    updateSummaryField,
    submit,
    resetForm,
    resetFormRequest,
    abortReset,
    blurField,
    loadBalance,
    listenBalance,
}, dispatch);

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    steps: {
        ...stateProps.steps,
        sector: {
            component: stateProps.home.role === 'exposant' ? StructureComponent : SectorComponent,
            updateField: 'updateSectorField',
            previous: 'coordinates',
            next: 'registration',
        }
    },
    ...dispatchProps,
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(FormComponent);
