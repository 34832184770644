import React from 'react';

class StepComponent extends React.Component {
    handleOnChangeForTextField(key, opts = {}) {
        return (event) => {
            this.props.updateField(key, event.target.value, opts);
        }
    }

    handleOnChangeForSelectField(key) {
        return event => { this.props.updateField(key, event.option); }
    }

    handleOnChangeForSelectOrTextField(key) {
        return event => { this.props.updateField(key, event.value); }
    }

    handleOnChangeForRadioField(key) {
        return event => { this.props.updateField(key, event.target.value); }
    }

    handleOnChangeForCheckboxField(key, single = false) {
        if (single) {
            return _event => this.props.updateField(key, !this.props[key]);
        }

        return event => {
            let value = this.props[key].slice();
            if (value.indexOf(event.target.value) >= 0) {
                value.splice(value.indexOf(event.target.value), 1);
            } else {
                value.push(event.target.value);
            }
            this.props.updateField(key, value);
        }
    }

    handleOnBlur(key) {
        return event => {
            this.props.blurField(key);
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.updateValidity(this.isValid());
    }

    componentDidUpdate() {
        this.props.updateValidity(this.isValid());
    }

    connectText(key, opts = {}) {
        return {
            value: this.props[key] === 'other' ? '' : this.props[key],
            onDOMChange: this.handleOnChangeForTextField(key, opts),
            onBlur: this.handleOnBlur(key, opts),
            onSelect: (itemSelected) => this.props.updateField(key, itemSelected.suggestion, opts)
        };
    }

    connectRadio(key, option, defaultChecked = false) {
        let checked = this.props[key] === option;
        if (this.props[key] === undefined) {
            checked = defaultChecked;
        }

        return {
            value: option,
            checked: checked,
            onChange: this.handleOnChangeForRadioField(key),
        };
    }

    connectCheckbox(key, option, single = false) {
        if (single) {
            return {
                value: option,
                checked: this.props[key],
                onChange: this.handleOnChangeForCheckboxField(key, single),
            }
        }

        return {
            value: option,
            checked: this.props[key].indexOf(option) >= 0,
            onChange: this.handleOnChangeForCheckboxField(key, single),
        }
    }

    connectSelect(key) {
        return {
            onChange: this.handleOnChangeForSelectField(key)
        }
    }

    connectSelectOrText(key) {
        return {
            value: this.props[key],
            onChange: this.handleOnChangeForSelectOrTextField(key),
            onBlur: this.handleOnBlur(key)
        }
    }
}

export default StepComponent;
