import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchDetails, changeDetailsField, saveInscription, deleteInscription } from '../../modules/inscriptions';
import InscriptionComponent from '../../components/inscription';
import { withRouter } from 'react-router'

const mapStateToProps = state => ({ 
    inscription: state.inscriptions.details,
    config: state.config.data,
    saving: state.inscriptions.saving,
    deleting: state.inscriptions.deleting,
    token: state.authentication.token,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchDetails,
    changeDetailsField,
    saveInscription,
    deleteInscription,
}, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(InscriptionComponent));
