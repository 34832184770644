import React from 'react';
import StepComponent from '../step';
import { RadioButton, FormField, Box, TextInput, Heading } from 'grommet';
import SelectOrText from '../fields/SelectOrText';
import { isFieldValid, allValid } from '../../helpers/validation';
import { findSuggestions } from '../../helpers/suggestions';
import WarningMessage from '../fields/warning-message';

export const SECTOR_FUNCTION = 'sectorFunction';
export const SECTOR_FUNCTION_OTHER = 'sectorFunctionOther';
export const SECTOR_STRUCTURE = 'sectorStructure';
export const SECTOR_STRUCTURE_OTHER = 'sectorStructureOther';
export const SECTOR_TYPE = 'sectorType';
export const SECTOR_NAME = 'sectorName';
export const ADDRESS_STREET = 'address:street';
export const ADDRESS_CS = 'address:cs';
export const ADDRESS_ZIPCODE = 'address:zipcode';
export const ADDRESS_CITY = 'address:city';
export const ADDRESS_CEDEX = 'address:cedex';

class SectorComponent extends StepComponent {

    getFieldValidations(checkDirty = false) {
        return {
            [SECTOR_TYPE]: isFieldValid({propName: SECTOR_TYPE, value: this.props[SECTOR_TYPE], type: 'string', mandatory: true}, checkDirty, this.props.dirty),
            ...(
                this.props[SECTOR_TYPE] === 'Public'
                    ? {
                        [SECTOR_NAME]: isFieldValid({
                            propName: SECTOR_NAME,
                            value: this.props[SECTOR_NAME],
                            type: 'string',
                            mandatory: true
                        }, checkDirty, this.props.dirty)
                    }
                    : {}
            ),
            ...(
                this.props[SECTOR_FUNCTION] === 'other'
                ? {
                    [SECTOR_FUNCTION_OTHER]: isFieldValid({
                    propName: SECTOR_FUNCTION_OTHER,
                    value: this.props[SECTOR_FUNCTION_OTHER],
                    type: 'string',
                    mandatory: true
                    }, checkDirty, this.props.dirty)
                }
                : {}
            ),
            ...(
                this.props[SECTOR_STRUCTURE] === 'other'
                ? {
                    [SECTOR_STRUCTURE_OTHER]: isFieldValid({
                    propName: SECTOR_STRUCTURE_OTHER,
                    value: this.props[SECTOR_STRUCTURE_OTHER],
                    type: 'string',
                    mandatory: true
                    }, checkDirty, this.props.dirty)
                }
                : {}
            ),
            [SECTOR_STRUCTURE]: isFieldValid({propName: SECTOR_STRUCTURE, value: this.props[SECTOR_STRUCTURE], type: 'string', mandatory: true}, checkDirty, this.props.dirty),
            [SECTOR_FUNCTION]: isFieldValid({propName: SECTOR_FUNCTION, value: this.props[SECTOR_FUNCTION], type: 'string', mandatory: true}, checkDirty, this.props.dirty),
            [ADDRESS_STREET]: isFieldValid({propName: ADDRESS_STREET, value: this.props[ADDRESS_STREET], type: 'string', mandatory: true}, checkDirty, this.props.dirty),
            [ADDRESS_ZIPCODE]: isFieldValid({propName: ADDRESS_ZIPCODE, value: this.props[ADDRESS_ZIPCODE], type: 'string', mandatory: true}, checkDirty, this.props.dirty),
            [ADDRESS_CITY]: isFieldValid({propName: ADDRESS_CITY, value: this.props[ADDRESS_CITY], type: 'string', mandatory: true}, checkDirty, this.props.dirty),
        };
    }

    isValid() {
        return allValid(this.getFieldValidations(true));
    }

    addressForm() {
        return (
            <Box>
                <Box direction="row">
                    <Box size="xlarge" direction="row" align="center">
                        <WarningMessage when={!this.getFieldValidations()['address:street']} severity="error" message="Ce champ est obligatoire" />
                        <FormField>
                            <TextInput placeHolder="Adresse" {...this.connectText(ADDRESS_STREET)} />
                        </FormField>
                    </Box>
                    <Box direction="row">
                        <FormField>
                            <TextInput placeHolder="BP/CS" {...this.connectText(ADDRESS_CS)} />
                        </FormField>
                    </Box>
                </Box>
                <Box direction="row">
                    <Box size="auto" direction="row">
                        <WarningMessage when={!this.getFieldValidations()['address:zipcode']} severity="error" message="Ce champ est obligatoire" />
                        <FormField>
                            <TextInput placeHolder="Code postal" {...this.connectText(ADDRESS_ZIPCODE)} />
                        </FormField>
                    </Box>
                    <Box size="medium" direction="row">
                        <WarningMessage when={!this.getFieldValidations()['address:city']} severity="error" message="Ce champ est obligatoire" />
                        <FormField>
                            <TextInput placeHolder="Commune" {...this.connectText(ADDRESS_CITY)} />
                        </FormField>
                    </Box>
                    <Box size="auto" direction="row">
                        <FormField>
                            <TextInput placeHolder="Cedex" {...this.connectText(ADDRESS_CEDEX)} />
                        </FormField>
                    </Box>
                </Box>
            </Box>
        );
    }

    addOtherField(optionArray) {
        optionArray.forEach((options) => {
            options.push({
                label: 'Autre...',
                value: 'other',
            });
        });
    }

    getSectorStructureOther() {
        if (this.props.sectorStructure === 'other') {
            return (<Box>
                <WarningMessage when={!this.getFieldValidations().sectorStructureOther} severity="error"
                                message="Ce champ est obligatoire"/>
                <FormField className="input-full-width">
                    <TextInput placeHolder="Nom de la structure" {...this.connectText(SECTOR_STRUCTURE_OTHER, { maxLength: 41 })} />
                </FormField>
            </Box>);
        }
    }

    getSectorFunctionOther() {
        if (this.props.sectorFunction === 'other') {
            return (<Box>
                <WarningMessage when={!this.getFieldValidations().sectorFunctionOther} severity="error"
                                message="Ce champ est obligatoire"/>
                <FormField className="input-full-width">
                    <TextInput placeHolder="Nom de votre fonction" {...this.connectText(SECTOR_FUNCTION_OTHER, { maxLength: 20 })} />
                </FormField>
            </Box>);
        }
        return null;
    }

    typePublicForm() {

        const townships = findSuggestions(this.props.config.sectors.public.townHall.townships, this.props[SECTOR_STRUCTURE]);
        const townhallFunctions = [...this.props.config.sectors.public.townHall.functions];
        const epcis = findSuggestions(this.props.config.sectors.public.community.epcis, this.props[SECTOR_STRUCTURE]);
        const epciFunctions = [...this.props.config.sectors.public.community.functions];
        const publicServices = [...this.props.config.sectors.public.other.services];
        this.addOtherField([townhallFunctions, epciFunctions, publicServices]);

        const mairieForm = (
            <Box className="form-group" pad="medium">
                <Box size="xlarge"pad="medium">
                    Saisissez directement les premières lettres de votre commune ou intercommunalité pour faire apparaître une sélection correspondant à votre saisie.
                </Box>
                <Box>
                    <WarningMessage when={!this.getFieldValidations().sectorStructure} severity="error" message="Ce champ est obligatoire" />
                    <FormField className="select-full-width">
                        <TextInput placeHolder="Saisir les premières lettres de la commune" id="township-input" name="township-input" suggestions={townships} {...this.connectText(SECTOR_STRUCTURE, { maxLength: 41 })} />
                    </FormField>
                </Box>
                <Box>
                    <WarningMessage when={!this.getFieldValidations().sectorFunction} severity="error" message="Ce champ est obligatoire" />
                    <SelectOrText textfieldPlaceHolder="Nom de votre fonction" placeHolder="Fonction" enableSearch={true} options={townhallFunctions}  {...this.connectSelectOrText(SECTOR_FUNCTION)} />
                </Box>
                {this.getSectorFunctionOther()}
                { this.addressForm() }
            </Box>
        );

        const metropoleForm = (
            <Box className="form-group" pad="medium">
                <Box size="xlarge"pad="medium">
                    Saisissez directement les premières lettres de votre commune ou intercommunalité pour faire apparaître une sélection correspondant à votre saisie.
                </Box>
                <Box>
                    <WarningMessage when={!this.getFieldValidations().sectorStructure} severity="error" message="Ce champ est obligatoire" />
                    <FormField className="select-full-width">
                        <TextInput placeHolder="Saisissez les premières lettres de l'intercommunalité" id="township-input" name="township-input" suggestions={epcis} {...this.connectText(SECTOR_STRUCTURE, { maxLength: 41 })} />
                    </FormField>
                </Box>
                <Box>
                  <WarningMessage when={!this.getFieldValidations().sectorFunction} severity="error" message="Ce champ est obligatoire" />
                  <SelectOrText textfieldPlaceHolder="Nom de votre fonction" placeHolder="Fonction" enableSearch={true} options={epciFunctions}  {...this.connectSelectOrText(SECTOR_FUNCTION)} />
                </Box>
                {this.getSectorFunctionOther()}
                { this.addressForm() }
            </Box>
        );

        const otherForm = (
            <Box className="form-group" pad="medium">
                <Box pad={{horizontal: "small"}}>
                    Sélectionnez votre structure dans le menu déroulant
                </Box>
                <Box>
                    <WarningMessage when={!this.getFieldValidations().sectorStructure} severity="error" message="Ce champ est obligatoire" />
                    <SelectOrText textfieldPlaceHolder="Nom de la structure" placeHolder="Structure" enableSearch={true} options={publicServices}  {...this.connectSelectOrText(SECTOR_STRUCTURE)} />
                </Box>
                { this.getSectorStructureOther() }
                <Box>
                    <WarningMessage when={!this.getFieldValidations().sectorFunction} severity="error" message="Ce champ est obligatoire" />
                    <FormField className="select-full-width">
                        <TextInput placeHolder="Fonction" id="public-other-function-input" name="public-other-function-input" {...this.connectText(SECTOR_FUNCTION, { maxLength: 20 })} />
                    </FormField>
                </Box>
                { this.addressForm() }
            </Box>
        );

        return (
            <div>
                <Heading tag="h2" className="form-group-title">Secteur public</Heading>
                <Box className="form-group" pad="medium">
                    <Box direction="row">
                        <RadioButton label="Mairie" name="sectorNameOptions" id="sectorNameMairie" {...this.connectRadio(SECTOR_NAME, 'Mairie')} />
                        <RadioButton label="CC / CA / Métropole" name="sectorNameOptions" id="sectorNameMetropole" {...this.connectRadio(SECTOR_NAME, 'Collectivité')} />
                        <RadioButton label="Autre" name="sectorNameOptions" id="sectorNameOther" {...this.connectRadio(SECTOR_NAME, 'Autre')} />
                    </Box>
                </Box>
                { this.props.sectorName === 'Mairie' ? mairieForm : '' }
                { this.props.sectorName === 'Collectivité' ? metropoleForm : '' }
                { this.props.sectorName === 'Autre' ? otherForm : '' }
            </div>
        );
    }

    typePrivateForm() {
        return (
            <div>
                <Heading tag="h2" className="form-group-title">Secteur privé</Heading>
                <Box className="form-group" pad="medium">
                    <Box>
                        <WarningMessage when={!this.getFieldValidations()[SECTOR_STRUCTURE]} severity="error" message="Ce champ est obligatoire" />
                        <FormField className="input-full-width">
                            <TextInput placeHolder="Entrez le nom de votre structure" id="sectorPrivateCorporation" name="sectorPrivateCorporation" {...this.connectText(SECTOR_STRUCTURE, { maxLength: 41 })} />
                        </FormField>
                    </Box>
                    <Box>
                        <WarningMessage when={!this.getFieldValidations()[SECTOR_FUNCTION]} severity="error" message="Ce champ est obligatoire" />
                        <FormField className="input-full-width">
                            <TextInput placeHolder="Fonction" id="sectorPrivateFunction" name="sectorPrivateFunction" {...this.connectText(SECTOR_FUNCTION, { maxLength: 20 })} />
                        </FormField>
                    </Box>
                    {this.addressForm()}
                </Box>
            </div>
        );
    }

    typeOtherForm() {
        const otherFunctions = this.props.config.sectors.other.slice();
        this.addOtherField([otherFunctions]);

        return (
            <div>
                <Heading tag="h2" className="form-group-title">Autre</Heading>
                <Box className="form-group" pad="medium">
                    <Box>
                        <FormField className="input-full-width">
                            <TextInput placeHolder="Entrez le nom de votre structure" {...this.connectText(SECTOR_STRUCTURE, { maxLength: 41 })} />
                        </FormField>
                    </Box>
                    <Box>
                        <WarningMessage when={!this.getFieldValidations().sectorFunction} severity="error" message="Ce champ est obligatoire" />
                        <SelectOrText textfieldPlaceHolder="Nom de votre fonction" placeHolder="Fonction" enableSearch={true} options={otherFunctions} {...this.connectSelectOrText(SECTOR_FUNCTION)} />
                    </Box>
                    {this.getSectorFunctionOther()}
                    {this.addressForm()}
                </Box>
            </div>
        );
    }

    sectorTypeForm() {
        switch (this.props.sectorType) {
            case 'Public':
                return this.typePublicForm();
            case 'Privé':
                return this.typePrivateForm();
            case 'Autre':
                return this.typeOtherForm();
            default:
                return '';
        }
    }

    render () {
        return (
            <div>
                <Heading tag="h2" className="form-group-title">Quel est votre secteur d'activité ?</Heading>
                <Box className="form-group" pad="medium">
                    <Box direction="row">
                            <WarningMessage when={!this.getFieldValidations()[SECTOR_TYPE]} severity="error" message="Ce champ est obligatoire" />
                            <RadioButton label="Secteur public" name="sectorTypeOptions" id="sectorTypePublic" {...this.connectRadio(SECTOR_TYPE, 'Public')} />
                            <RadioButton label="Secteur privé" name="sectorTypeOptions" id="sectorTypePrivate" {...this.connectRadio(SECTOR_TYPE, 'Privé')} />
                            <RadioButton label="Autre" name="sectorTypeOptions" id="sectorTypeOther" {...this.connectRadio(SECTOR_TYPE, 'Autre')} />
                    </Box>
                </Box>

                { this.sectorTypeForm() }
            </div>
        );
    }
}

export default SectorComponent;
