import React from 'react';
import { Button, Box, Heading } from 'grommet';

export default class Confirm extends React.Component {
    render() {
        return (<Box pad="medium">
            <Heading tag="h3" className="confirm-title">{this.props.title || ''}</Heading>
            <Box pad={{ vertical: 'medium' }}>{this.props.body || ''}</Box>
            <Box direction="row" justify="end">
                <Box pad={{ horizontal: 'small' }}>
                    <Button primary={true} className="confirm-cancel-button" label={this.props.label || 'Annuler'}
                            onClick={() => this.props.onCancel ? this.props.onCancel() : null}/>
                </Box>
                <Box>
                    <Button className="confirm-submit-button" label={this.props.label || 'Confirmer'}
                            onClick={() => this.props.onSubmit ? this.props.onSubmit() : null}/>
                </Box>
            </Box>
        </Box>);
    }

}
