import React from 'react';
import BreadcrumbStep from './step';
import { Box } from 'grommet';

export default class Breadcrumbs extends React.Component {
    render() {
        const steps = [
            {
                id: "home",
                key: "home",
                label: "Accueil",
                state: this.props.currentStepKey === 'home' ? 'current' : this.props.completedSteps.indexOf('home') >= 0 ? 'complete' : 'off'
            },
            {
                id: "coordinates",
                key: "coordinates",
                label: "Coordonnées",
                state: this.props.currentStepKey === 'coordinates' ? 'current' : this.props.completedSteps.indexOf('coordinates') >= 0 ? 'complete' : 'off'
            },
            {
                id: "sector",
                key: "sector",
                label: this.props.selectedRole === 'exposant' ? 'Structure' : 'Secteur',
                state: this.props.currentStepKey === 'sector' ? 'current' : this.props.completedSteps.indexOf('sector') >= 0 ? 'complete' : 'off'
            },
            {
                id: "registration",
                key: "registration",
                label: "Inscription",
                state: this.props.currentStepKey === 'registration' ? 'current' : this.props.completedSteps.indexOf('registration') >= 0 ? 'complete' : 'off'
            },
            {
                id: "summary",
                key: "summary",
                label: "Récapitulatif",
                state: this.props.currentStepKey === 'summary' ? 'current' : this.props.completedSteps.indexOf('summary') >= 0 ? 'complete' : 'off'
            },
            {
                id: "validation",
                key: "validation",
                label: "Validation",
                state: this.props.currentStepKey === 'validation' ? 'current' : this.props.completedSteps.indexOf('validation') >= 0 ? 'complete' : 'off'
            },
        ];

        const stepButtons = steps.map((step, index) => {
            return (
                <BreadcrumbStep
                    {...step}
                    prependArrow={index > 0}
                />
            );
        });

        return (<Box
            flex={true}
            direction="row"
            size="full"
            responsive={false}
            pad={{ horizontal: "small" }}
            justify="between">
            {stepButtons}
        </Box>);
    }
}
