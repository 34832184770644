import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { cloneDeep } from 'lodash';
import createRootReducer from './modules'
import persistState from 'redux-localstorage'

export const history = createBrowserHistory();

const initialState = JSON.parse(window.localStorage.getItem('redux')) || {};

const propertiesToCleanDirty = ['coordinates', 'registration', 'sector'];
const enhancers = [
    persistState('authentication', { key: 'authentication' }),
    persistState('form', {
        key: `form-v${process.env.VERSION}`,
        slicer: (path) => {
            return (state) => {
                let subset = { [path]: cloneDeep(state[path]) };

                for (const prop of propertiesToCleanDirty) {
                    if (!subset[path][prop]) {
                        continue;
                    }
                    subset[path][prop] = { ...subset[path][prop], dirty: [] }
                }

                return subset
            }
        },
    })
];

const middleware = [
    thunk,
    routerMiddleware(history)
];

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const store = createStore(
    createRootReducer(history),
    initialState,
    composedEnhancers
);

export default store
