import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Layer, Button, Box, Header, Heading } from 'grommet';
import Spinning from 'grommet/components/icons/Spinning';
import ArrowIcon from 'grommet/components/icons/base/FormNextLink';
import BackArrowIcon from 'grommet/components/icons/base/FormPreviousLink';
import Confirm from './confirm';
import Breadcrumbs from '../breadcrumbs';
import renderHTML from 'react-render-html';

import './index.scss';

class FormComponent extends React.Component {
    UNSAFE_componentWillMount() {
        this.props.loadBalance()
        this.props.listenBalance()
    }

    get currentStep() {
        return this.props.steps[this.props.currentStepKey];
    }

    updateCompletedSteps() {
        if (this.currentStep.previous && this.props.completedSteps.indexOf(this.currentStep.previous) === -1) {
            const newVal = this.props.completedSteps.slice();
            newVal.push(this.currentStep.previous);
            this.props.updateCompletedStep(newVal);
        }
    }

    generateCurrentStepNavigationFunctions() {
        const { previous, next, submit, cancel, reset } = this.currentStep;
        this.updateCompletedSteps();
        return {
            previous: previous ? () => this.props.updateStep(previous) : null,
            next: next && !submit ? () => this.props.updateStep(next) : null,
            submit: submit ? () => this.props.submit({ ...this.props, nextStepKey: next }) : null,
            cancel: cancel ? (cancel) => this.props.resetFormRequest() : null,
            reset: reset ? (reset) => this.props.resetForm() : null,
        };
    }

    render() {
        const { component: StepComponent, updateField } = this.currentStep;
        const { previous, next, submit, cancel, reset } = this.generateCurrentStepNavigationFunctions();

        const previousButton = previous ? (<Button type="button" onClick={previous} label="Précédent"
            icon={<BackArrowIcon
                colorIndex="brand-accent-color" />} />) : null;
        const nextButton = next ? (<Button primary={true} type="button" className="icon-right"
            onClick={this.props.currentStepIsValid ? next : undefined} label="Suivant"
            icon={<ArrowIcon />} />) : null;
        const submitButton = submit ? (<Button type="button" primary={true} onClick={(!this?.props?.summary?.rulesAccepted || this?.props?.submitting) ? null : submit} label="Valider" />) : null;
        const cancelButton = cancel ? (
            <Button type="button" onClick={cancel} label="Annuler" secondary={true} />) : null;
        const resetButton = reset ? (<Button className="w-100" primary={true} type="button" onClick={reset}
            label="Soumettre une nouvelle participation" />) : null;

        const cancelConfirm = (<Layer closer={true}><Confirm
            title="Êtes-vous sûr ?"
            body="Vous êtes sur le point de réinitialiser le formulaire, toutes vos saisies seront perdues."
            onSubmit={() => this.props.resetForm()}
            onCancel={() => this.props.abortReset()}
        /></Layer>);

        return !this.props.display ?
            (<Box full={true} align="center" justify="center"><Spinning size="huge" /></Box>) :
            (<Box className="form">
                <Header className="header" fixed={true} justify="center">
                    <Box basis="1/4" className="sidebar">
                    </Box>
                    <Box className="dblock box-breadcrumb" alignSelf="center" basis="3/4">
                        <Breadcrumbs selectedRole={this.props.home.role} completedSteps={this.props.completedSteps} currentStepKey={this.props.currentStepKey} />
                    </Box>
                </Header>
                <Box direction="row" justify="center" className="main-container">
                    <Box basis="1/4" className="sidebar">
                        <div className="flyer">
                            <img alt="flyer" src="/assets/img/flyer.png" />
                            {!!this.props.config ? renderHTML(this.props.config.messages.sidebar) : ''}
                            {(!!this.props.config && (Date.now() <= new Date(this.props.config.limitDatetime))) ? renderHTML(this.props.config.messages.help) : ''}
                        </div>
                    </Box>
                    {
                        (!!this.props.config && (Date.now() <= new Date(this.props.config.limitDatetime))) && (
                            <Box basis="3/4" className="content-form">
                                <Box pad="medium">
                                    <StepComponent
                                        {...(this.props.currentStepKey === 'summary' || this.props.currentStepKey === 'validation' ? { ...this.props, ...this.props[this.props.currentStepKey] } : this.props[this.props.currentStepKey])}
                                        home={this.props.home}
                                        balance={this.props.balance}
                                        config={this.props.config}
                                        updateField={this.props[updateField]}
                                        blurField={this.props.blurField}
                                        updateValidity={this.props.updateValidity}
                                    />
                                </Box>
                                <Box direction="row" justify="between" pad={{ horizontal: "medium" }}>
                                    <Box direction="row">
                                        {previousButton}
                                    </Box>
                                    <Box direction="row" justify="end">
                                        <Box pad={{ horizontal: 'small' }}>
                                            {cancelButton}
                                        </Box>
                                        <Box>
                                            {nextButton}
                                        </Box>
                                        <Box>
                                            {submitButton}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box pad={{ horizontal: "medium" }}>
                                    {resetButton}
                                </Box>
                                <NavigationPrompt when={true}
                                    message="Vous êtes sur le point de quitter le formulaire d'inscription, êtes-vous sûr ?">
                                    {({ isActive, onConfirm, onCancel }) => {
                                        if (isActive) {
                                            return cancelConfirm;
                                        }
                                    }}
                                </NavigationPrompt>
                                {this.props.cancelling ? cancelConfirm : ''}
                            </Box>
                        )
                    }
                    {
                        (!!this.props.config && (Date.now() > new Date(this.props.config.limitDatetime))) && (
                            <Box basis="3/4" className="content-form">
                                <Box pad="medium">
                                    <Heading tag="h2">Les inscriptions en ligne sont cloturées.</Heading>
                                    {!!this.props.config ? renderHTML(this.props.config.messages.help) : ''}
                                </Box>
                            </Box>
                        )
                    }
                </Box>
            </Box>);
    }
}

export default FormComponent;
