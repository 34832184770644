import React from "react";
import {
    SECTOR_FUNCTION,
    SECTOR_STRUCTURE,
    SECTOR_TYPE,
    SECTOR_NAME,
    SECTOR_FUNCTION_OTHER,
    SECTOR_STRUCTURE_OTHER,
} from "../sector";
import { Box, Heading, CheckBox } from "grommet";
import StepComponent from "../step";
import renderHTML from "react-render-html";

class SummaryComponent extends StepComponent {
    isValid() {
        return true;
    }

    getSectorData() {
        const sector = this.props.sector;
        const registration = this.props.registration;

        const addressData = {
            street: sector["address:street"],
            cs: sector["address:cs"],
            zipcode: sector["address:zipcode"],
            city: sector["address:city"],
            cedex: sector["address:cedex"],
        };
        const invoicingAddressData =
            registration["invoicing-address:street"] &&
            registration["invoicing-address:zipcode"] &&
            registration["invoicing-address:city"]
                ? {
                      street: registration["invoicing-address:street"],
                      cs: registration["invoicing-address:cs"],
                      zipcode: registration["invoicing-address:zipcode"],
                      city: registration["invoicing-address:city"],
                      cedex: registration["invoicing-address:cedex"],
                  }
                : addressData;

        return {
            sectorFunction:
                sector[SECTOR_FUNCTION] === "other"
                    ? sector[SECTOR_FUNCTION_OTHER]
                    : sector[SECTOR_FUNCTION],
            sectorStructure:
                sector[SECTOR_STRUCTURE] === "other"
                    ? sector[SECTOR_STRUCTURE_OTHER]
                    : sector[SECTOR_STRUCTURE],
            sectorName: sector[SECTOR_NAME],
            sectorType: sector[SECTOR_TYPE],
            address: addressData,
            invoicingAddress: invoicingAddressData,
        };
    }

    mealSummary(day) {
        if (this?.props?.home?.role === "exposant") {
            return (
                <div>
                    Pour la restauration, prendre contact avec l’Agence BERGAME
                    au 02 98 46 05 17 ou{" "}
                    <a
                        href="mailto:ccf2024@agence-bergame.bzh"
                        target="_blank"
                        rel="noreferrer"
                    >
                        ccf2024@agence-bergame.bzh
                    </a>
                    .
                </div>
            );
        }

        let addressString;
        let mealPaymentMode;
        let mealItems;
        switch (this.props.registration.meal) {
            case "réservation":
                addressString =
                    this.getSectorData().invoicingAddress.street + " ";
                addressString += this.getSectorData().invoicingAddress.cs
                    ? this.getSectorData().invoicingAddress.cs + " "
                    : "";
                addressString +=
                    this.getSectorData().invoicingAddress.zipcode + " ";
                addressString +=
                    this.getSectorData().invoicingAddress.city + " ";
                addressString += this.getSectorData().invoicingAddress.cedex
                    ? this.getSectorData().invoicingAddress.cedex
                    : "";

                mealPaymentMode = this.props.registration.mealPayment;

                mealItems = [
                    {
                        label: "Total :",
                        value:
                            day.meal.price +
                            "€ TTC (Réservation définitive et non modifiable)",
                    },
                    { label: "Mode de règlement :", value: renderHTML(mealPaymentMode) },
                    { label: "Adresse de facturation :", value: addressString },
                ];

                return (
                    <div>
                        <b>Réservation de votre déjeuner en salon de restauration officiel</b>
                        {this.makeSummaryBlock(mealItems)}
                    </div>
                );
            case "foodtruck":
                addressString =
                    this.getSectorData().invoicingAddress.street + " ";
                addressString += this.getSectorData().invoicingAddress.cs
                    ? this.getSectorData().invoicingAddress.cs + " "
                    : "";
                addressString +=
                    this.getSectorData().invoicingAddress.zipcode + " ";
                addressString +=
                    this.getSectorData().invoicingAddress.city + " ";
                addressString += this.getSectorData().invoicingAddress.cedex
                    ? this.getSectorData().invoicingAddress.cedex
                    : "";

                mealPaymentMode = this.props.registration.mealPayment;

                mealItems = [
                    {
                        label: "Total :",
                        value:
                            day.meal.price +
                            "€ TTC (Réservation définitive et non modifiable)",
                    },
                    { label: "Mode de règlement :", value: mealPaymentMode },
                    { label: "Adresse de facturation :", value: addressString },
                ];

                return (
                    <div>
                        <b>Réservation de votre déjeuner à la Brasserie du Quartz</b>
                        {this.makeSummaryBlock(mealItems)}
                    </div>
                );
            default:
                return "pas de déjeuner";
        }
    }

    getEventValue(eventData) {
        const event = this.props.balance.events.find(
            (evt) => evt.id === eventData.id
        );
        if (!event) {
            return "";
        }
        if (event.registrationsBalance <= 0) {
            return "Liste d'attente";
        } else return "OUI";
    }

    eventsSummary() {
        const registration = this.props.registration;
        const days = registration.presenceDay.split(",");

        const daysData = this.props.config.days.filter((day) => {
            return days.indexOf(day.key) >= 0;
        });

        const daysBlock = daysData.map((day) => {
            const registeredEventItems =
                this.props.registration.registeredToEvents
                    .filter((eventId) => {
                        return eventId.split(":")[1] === day.key;
                    })
                    .reduce((acc, eventId) => {
                        const dayKey = eventId.split(":")[1];
                        const eventData = this.props.config.days
                            .find((day) => day.key === dayKey)
                            .events.find((event) => event.id === eventId);
                        if (eventData)
                            return [
                                ...acc,
                                {
                                    id: eventData.id,
                                    label: eventData.title,
                                    value: this.getEventValue(eventData),
                                },
                            ];
                        return acc;
                    }, [])
                    .sort((a, b) => {
                        if (a.id < b.id) {
                            return -1;
                        }

                        if (a.id > b.id) {
                            return 1;
                        }

                        return 0;
                    });

            return (
                <div key={"day-" + day.key}>
                    <Box className="form-group" pad="medium">
                        <Heading tag="h3">
                            {day.labelDay + " " + day.labelMonth}
                        </Heading>
                        {days.includes(day.key) && (
                            <Box pad={{ vertical: "small" }}>
                                Participation au 10ème Carrefour des Communes
                            </Box>
                        )}
                        {registeredEventItems.length > 0 && (
                            <Box pad={{ vertical: "small" }}>
                                {this.makeSummaryBlock(registeredEventItems)}
                            </Box>
                        )}
                        <Box>
                            {day.key === "first" ? this.mealSummary(day) : ""}
                        </Box>
                    </Box>
                </div>
            );
        });

        return <div>{daysBlock}</div>;
    }

    makeSummaryBlock(data) {
        const block = data.map((line) => {
            return this.makeSummaryLine(line);
        });
        return block;
    }

    makeSummaryLine(data) {
        return (
            <div key={data.label}>
                <Box direction="row">
                    <Box size="medium">{data.label}</Box>
                    {/* <Box size="medium">{data.value}</Box> */}
                    {(data.label === 'Nom :') ?
                        <Box size="medium">{data.value?.toUpperCase()}</Box>
                    :
                        <Box size="medium">{data.value}</Box>
                    }
                </Box>
            </div>
        );
    }

    render() {
        const coordinatesItems = [
            {
                label: "Civilité :",
                value:
                    this.props.coordinates.gender === "female"
                        ? "Madame"
                        : "Monsieur",
            },
            { label: "Nom :", value: this.props.coordinates.lastname },
            { label: "Prénom :", value: this.props.coordinates.firstname },
            { label: "Courriel :", value: this.props.coordinates.email },
            { label: "Téléphone :", value: this.props.coordinates.phone },
        ];

        const sectorItems = [
            {
                label:
                    this.props.home.role === "exposant"
                        ? "Structure"
                        : "Collectivité / structure :",
                value:
                    this.getSectorData().sectorName === "Mairie" &&
                    this.getSectorData().sectorType === "Public"
                        ? "Mairie de " + this.getSectorData().sectorStructure
                        : this.getSectorData().sectorStructure,
            },
            { label: "Fonction :", value: this.getSectorData().sectorFunction },
            { label: "Adresse :", value: this.getSectorData().address.street },
            { label: "BP/CS :", value: this.getSectorData().address.cs },
            {
                label: "Code postal :",
                value: this.getSectorData().address.zipcode,
            },
            { label: "Commune :", value: this.getSectorData().address.city },
            { label: "Cedex :", value: this.getSectorData().address.cedex },
        ];

        const attendanceCertificateBlock = (
            <Box className="form-group" pad="medium">
                {this.makeSummaryBlock([
                    { label: "Attestation de présence", value: "OUI" },
                ])}
            </Box>
        );
        return (
            <div>
                <Heading tag="h2" className="form-group-title">
                    Récapitulatif de votre inscription
                </Heading>
                <Box className="form-group" pad="medium">
                    <Box direction="row">
                        <Box size="medium">Type d'inscription</Box>
                        <Box size="medium">
                            {this.props.home.role === "congressiste"
                                ? "Congressiste"
                                : this.props.home.role === "exposant"
                                ? "Exposant"
                                : ""}
                        </Box>
                    </Box>
                </Box>
                <Box className="form-group" pad="medium">
                    <Heading tag="h3">Coordonnées</Heading>
                    {this.makeSummaryBlock(coordinatesItems)}
                </Box>
                <Box className="form-group" pad="medium">
                    <Heading tag="h3">
                        {this.props.home.role === "exposant"
                            ? "Structure"
                            : "Secteur"}
                    </Heading>
                    {this.makeSummaryBlock(sectorItems)}
                </Box>
                {this.eventsSummary()}
                <div>
                    {this.props.home.role === "congressiste" && (
                        <div>
                            {this.props.registration.attendanceCertificate
                                .length
                                ? attendanceCertificateBlock
                                : ""}
                        </div>
                    )}
                </div>
                <Box className="form-group" pad="medium" direction="row">
                    <CheckBox
                        label={
                            <span>
                                Je confirme avoir pris connaissance du{" "}
                                <a
                                    href={`${process.env.REACT_APP_API_HOST}/public/REGLEMENT_INTERIEUR_CCF_2024.pdf`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    règlement intérieur
                                </a>{" "}
                                (conditions de participation, droits à l'image,
                                traitement des données personnelles) et
                                l'accepte.
                            </span>
                        }
                        {...this.connectCheckbox("rulesAccepted", "oui", true)}
                    />
                </Box>
            </div>
        );
    }
}

export default SummaryComponent;
