import React from 'react';
import './warning-message.scss';

export default class WarningMessage extends React.Component {

    render() {
        const messageBox = (
            <div className={'warning-message-wrapper warning-message-wrapper-' + (this.props.severity || 'neutral')}>
                <div className={'warning-message'}>{this.props.message}</div>
                <div className="arrow">&#9654;</div>
            </div>
        );

        return (<div style={{position: 'relative'}}>
            {this.props.when ? messageBox : null}
        </div>);
    }
}
