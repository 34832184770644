import React from "react";
import StepComponent from "../step";
import { RadioButton, Box, Heading } from "grommet";
import { isFieldValid, allValid } from "../../helpers/validation";
import WarningMessage from "../fields/warning-message";
import renderHTML from "react-render-html";

import "../form/index.scss";
import "./index.scss";

class HomeComponent extends StepComponent {
    getFieldValidations(checkDirty = false) {
        return {
            role: isFieldValid(
                {
                    propName: "role",
                    value: this.props.role,
                    type: "string",
                    mandatory: true,
                },
                checkDirty,
                this.props.dirty
            ),
        };
    }

    isValid() {
        return allValid(this.getFieldValidations(true));
    }

    render() {
        return (
            <div>
                <Box pad="small">
                    {renderHTML(this.props.config.messages.homeHeader)}
                </Box>
                <Heading tag="h2" className="form-group-title">
                    Vous êtes :{" "}
                </Heading>
                <Box className="form-group" pad="medium">
                    <Box direction="row" className="checkbox-wrapper">
                        <WarningMessage
                            when={!this.getFieldValidations().role}
                            severity="error"
                            message="Ce champ est obligatoire"
                        />
                        <RadioButton
                            label="Congressiste, intervenant ou autre"
                            name="inlineRadioOptions"
                            id="roleDelegate"
                            {...this.connectRadio("role", "congressiste")}
                        />
                        <RadioButton
                            label="Exposant"
                            name="inlineRadioOptions"
                            id="roleExhibitor"
                            {...this.connectRadio("role", "exposant")}
                        />
                    </Box>
                </Box>
                <Box pad="small">
                    {renderHTML(this.props.config.messages.homeFooter)}
                </Box>
            </div>
        );
    }
}

export default HomeComponent;
