import React from 'react';
import { Box, Heading, Button } from 'grommet';
import '../form/index.scss';

class ValidationComponent extends React.Component {

    render() {
        // const pendingEvents = Object.keys(this.props.registrationResponse.registration.events).filter(key => this.props.registrationResponse.registration.events[key] === 'pending');
        const pendingEvents = this.props.registrationResponse &&
                      this.props.registrationResponse.registration &&
                      this.props.registrationResponse.registration.events
        ? Object.keys(this.props.registrationResponse.registration.events).filter(
            key => this.props.registrationResponse.registration.events[key] === 'pending'
            )
        : [];

        console.log('pendingEvents', pendingEvents);
        console.log('this.props.registrationResponse', this.props.registrationResponse);

        // if status of response is 422 (Unprocessable Entity) then we have to show the errors
        if (this.props.registrationResponse.status === 422) {
            return (
                <div>
                    <Heading tag="h2" className="form-group-title">Erreur lors de l'inscription</Heading>
                    <Box pad={{ vertical: 'small' }}>
                        {/* {this.props.registrationResponse?.message} */}
                        <b style={{ color: 'red' }}>{this.props.registrationResponse.message}</b>
                    </Box>
                    <Box pad={{ vertical: 'small' }}>
                        <Button type="button" primary={true} className="w-100"
                         onClick={this.props.resetForm}
                         label="Retour au formulaire"></Button>
                    </Box>
                </div>
            );
        }

        let pendingWarning = '';
        if (pendingEvents.length) {
            pendingWarning = pendingEvents.map(event => {
                const day = event.split(':')[1] === 'first' ? 0 : 1;
                const eventItem = this.props.config.days[day].events.find(evt => evt.id === event.id);
                if (!eventItem) {
                    return '';
                }

                return (<div>{eventItem.title}</div>);
            });

            pendingWarning.unshift(<div><b>Vous avez été mis·e sur la liste d'attente pour ces activités :</b></div>)
        }

        const confirmBlock = (
            <div>
                <Heading tag="h2" className="form-group-title">Merci de votre inscription !</Heading>
                <Box pad={{ vertical: 'small' }}>
                    <b>Votre participation a bien été prise en compte.</b>
                    Vous allez recevoir un e-mail de confirmation avec un lien vous permettant d’imprimer votre badge. (Dans le cas contraire, pensez à consulter vos messages indésirables)<br />
                    Vous pouvez également le télécharger ici :
                </Box>
                <Box margin={{ bottom: 'small' }}>
                    <Button type="button" primary={true} className="w-100" target="_blank" href={`${process.env.REACT_APP_API_HOST}/badge/${this.props.registrationResponse._id}/pdf`} label="Accéder à votre badge"></Button>
                </Box>
                <p><b>Vous devez impérativement vous présenter au Quartz Congrès-Brest muni de votre badge nominatif</b>, aucune inscription ne sera prise sur place.</p>
                <p>Les porte-badges et tours de cou seront en libre-service à l’accueil du Congrès.</p>
                <Heading tag="h2" className="form-group-title">Rendez-vous les 3 et 4 octobre 2024 au Quartz Congrès !</Heading>
                <Box pad={{ vertical: 'small' }}>
                    60 rue du Château à Brest.
                    <b>Ouverture des portes à 8h30.</b>
                </Box>

                <Box className="form-group" pad="medium">
                    <Box pad={{ vertical: 'small' }}>
                        <Heading tag="h3">Informations complémentaires</Heading>
                    </Box>
                    <Box pad={{ vertical: 'small' }}>
                        <span><b>Organisation générale, programme des deux jours de travaux, conférences et ateliers, animations :</b> <a rel="noreferrer" target="_blank" href="https://amf29.asso.fr/carrefour-des-communes-du-finistere/">https://amf29.asso.fr/carrefour-des-communes-du-finistere/</a></span><br />
                        <span><b>Contact :</b> AMF 29 - 02 98 33 88 70 - <a href="mailto:amf29@orange.fr">amf29@orange.fr</a></span>
                    </Box>
                    <Box pad={{ vertical: 'small' }}>
                        <b>Espace exposant, stationnement, restauration :</b>
                        <span>Agence BERGAME - 02 98 46 05 17 - <a href="mailto:ccf2024@agence-bergame.bzh">ccf2024@agence-bergame.bzh</a></span>
                    </Box>
                </Box>
            </div>
        );


        return confirmBlock;
    }
}

export default ValidationComponent;
