import React from 'react';

import Sidebar from 'grommet/components/Sidebar';
import Header from 'grommet/components/Header';
import Title from 'grommet/components/Title';
import Box from 'grommet/components/Box';
import Menu from 'grommet/components/Menu';
import Anchor from 'grommet/components/Anchor';
import Footer from 'grommet/components/Footer';
import Button from 'grommet/components/Button';


class SidebarComponent extends React.Component {
    render() {
        return (     
            <Sidebar colorIndex='neutral-1' fixed={true} size='small'>
                <Header pad='medium' justify='between'>
                    <Title>{this.props.title}</Title>
                </Header>
                <Box flex='grow' justify='start'>
                    <Menu primary={true}>
                        { (this.props.routes || []).map(route => <Anchor {...route} />)}
                    </Menu>
                </Box>
                <Footer pad='medium'>
                    { (this.props.actions || []).map(action => <Button {...action} plain secondary />)}
                </Footer>
            </Sidebar>
        );
    }
}

export default SidebarComponent;