export const matchString = (str1, str2) => {
    return !str1 || !str2 || str1.toLowerCase().indexOf(str2.toLowerCase()) > -1;
};

export const startsWith = (string, search) => {
    if(!string || !search) return true;
    return string.toLowerCase().startsWith(search.toLowerCase());
}

export const findSuggestions = (allSuggestions, searchText) => {
    const suggestions = allSuggestions.filter(suggestion => matchString(suggestion, searchText));

    return [
        ...suggestions.filter(suggestion => startsWith(suggestion, searchText)),
        ...suggestions.filter(suggestion => !startsWith(suggestion, searchText)),
    ];
};
