import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isLoggedIn } from '../../selectors/authentication';
import { logout } from '../../modules/authentication';
import PrivateRouteComponent from '../../components/private-route'

const mapStateToProps = state => {
    const authentication = state.authentication;
    return {
        config: state.config,
        isLoggedIn: isLoggedIn(authentication),
        token: authentication.token,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    logout,
}, dispatch);


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivateRouteComponent);
